import React, { memo } from "react"
import styled from "styled-components"

import Logo from "../../atoms/logo"
import LinkWrapper from "../../../utils/linkWrapper"

const LogoSection = ({ isInStoreFrontendParent }) => (
  <>
    <StyledLogo to="/">
      <Logo style={{ height: "60px" }} />
    </StyledLogo>
    {isInStoreFrontendParent && (
      <StyledFlatlist to="/flatlist/">
        <div>FLATLIST</div>
      </StyledFlatlist>
    )}
  </>
)

const StyledLogo = styled(LinkWrapper)`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledFlatlist = styled(LinkWrapper)`
  width: 160px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.ruby["060"]};
  border-radius: 20px;
  div {
    color: white;
    font-size: 32px;
    font-weight: 600;
  }
`

export default memo(LogoSection)
