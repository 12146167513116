import React, { useState, useMemo, useEffect } from "react"
import styled from "styled-components"
import LinkWrapper from "../../../utils/linkWrapper"
import { windowSizes, checkWindowSize } from "../../../utils/responsive"
import { CreateLocalLink } from "../../../lib/util"
import SvgIcon from "../../atoms/icons/svg-icon"
import ChevronDown from "../../atoms/icons/files/chevron-down.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Group = ({ node }) => {
  let isTiny = false
  isTiny = checkWindowSize({ maxWidth: windowSizes.tiny })

  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((prev) => !prev)

  const nodeUrl = CreateLocalLink(node.url)
  let parentProps = {}
  if (isTiny) {
    parentProps = { as: "div", onClick: toggle }
  } else if (nodeUrl) {
    parentProps = { as: LinkWrapper, to: nodeUrl }
  } else {
    parentProps = { as: "div" }
  }

  useEffect(() => {
    isTiny ? setOpen(false) : setOpen(true)
  }, [isTiny])

  return (
    <Wrapper>
      <Parent {...parentProps}>
        {node.label}
        {isTiny && (
          <SvgIcon
            SvgComponent={ChevronDown}
            className={open ? "open" : "closed"}
            hue="white"
            shade="000"
          />
        )}
      </Parent>
      <MobileFooterMenusCollapseStyling open={open}>
        {node.childItems.map((node, i) => {
          const localLink = CreateLocalLink(node.url)
          if (localLink) {
            return (
              <Child key={i}>
                <LinkWrapper to={localLink}>{node.label}</LinkWrapper>
              </Child>
            )
          } else {
            return (
              <Child key={i}>
                <p>{node.label}</p>
              </Child>
            )
          }
        })}
      </MobileFooterMenusCollapseStyling>
    </Wrapper>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Wrapper = styled.div`
  width: 100%;
`

const Parent = styled.h4`
  color: #fff;
  position: relative;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    &:after {
      width: 35px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.ruby["060"]};
    width: 25px;
    height: 2px;
    bottom: -2px;
    left: 0;
    transition: all 200ms ease-in-out;
  }
  > span.icon {
    transition: 0.2s all ease-in-out;
    &.open {
      transform: rotate(180deg);
    }
  }
`

const Child = styled.li`
  a {
    color: #b6c9e3;
    font-size: 0.85rem;
    transition: all 200ms ease-in-out;
    &:hover {
      color: #fff;
    }
  }
`

const MobileFooterMenusCollapseStyling = styled.ul`
  overflow: hidden;
  max-height: ${({ open }) => (open ? "100vh" : "0")};
  height: 100%;
  transition: all 0.3s ease-in-out;
`
export default Group
