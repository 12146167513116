import React from "react"
import styled from "styled-components"
import Inner from "../../../layout/pageInner/Inner"
import CardLink from "./CardLink"
import FeaturedCard from "./FeaturedCard"
import { graphql, useStaticQuery } from "gatsby"
import { CreateLocalLink, path } from "../../../../lib/util"

const BlogMenu = ({ links }) => {
  const data = useStaticQuery(LATEST_BLOG_POST)
  const node = data.localWpGraphQlPosts

  const randomIndex = Math.floor(Math.random() * 5)
  const randomFallback =
    data.allFile.nodes[randomIndex].childImageSharp.gatsbyImageData

  const image =
    node?.featuredImage?.imageFile?.localFile?.childImageSharp?.gatsbyImageData

  return (
    <Wrap>
      <Inner>
        <Grid>
          <div>
            <h4>Hot off the press...</h4>
            <FeaturedCard
              title={node.title}
              alt="blog-image"
              uri={"/sleep-matters/" + node.slug}
              image={image || randomFallback}
            />
          </div>
          <div>
            <h4>Select a category to get started</h4>
            <Categories>
              {links.map((node, i) => (
                <CardLink
                  key={i}
                  title={node.label}
                  to={CreateLocalLink(node.url)}
                />
              ))}
            </Categories>
          </div>
        </Grid>
      </Inner>
    </Wrap>
  )
}

export default BlogMenu

const Wrap = styled.div``

const Categories = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  padding: 40px 0;
  grid-gap: 20px;
  & > div > h4 {
    color: white;
    margin-bottom: 6px;
  }
`

const LATEST_BLOG_POST = graphql`
  {
    localWpGraphQlPosts {
      title
      uri
      slug
      featuredImage {
        sourceUrl
        mediaItemId
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "placeholders" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
