import React from "react"

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.822"
    height="8.822"
    viewBox="0 0 8.822 8.822"
  >
    <path
      fill="#fff"
      d="M16.322,8.389,15.434,7.5l-3.523,3.523L8.389,7.5,7.5,8.389l3.523,3.523L7.5,15.434l.889.889L11.911,12.8l3.523,3.523.889-.889L12.8,11.911Z"
      transform="translate(-7.5 -7.5)"
    />
  </svg>
)

export default CloseIcon
