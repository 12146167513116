import React, { useContext, useEffect, useState, memo } from "react"
import styled from "styled-components"

import CartContext from "../../../context/CartContext"

import { getCompareList } from "../../../components/Products/CompareProducts"

import CartIcon from "../../../components/CartIcon/CartIcon"
import SvgIcon from "../../atoms/icons/svg-icon"
import LocationPin from "../../atoms/icons/files/location-pin.jsx"
import ListCheck from "../../atoms/icons/files/list-check.jsx"

import LinkWrapper from "../../../utils/linkWrapper"

const IconSection = () => {
  const { setCartDrawerOpen, setCompareDrawerOpen, compareDrawer } = useContext(
    CartContext
  )
  const [compareCount, setCompareCount] = useState(0)
  useEffect(() => {
    if (compareDrawer) {
      const items = getCompareList()
      const length = items ? items.length : 0
      setCompareCount(length)
    }
  }, [compareDrawer])

  return (
    <IconsNav>
      <IconsBox to="/contact-us/">
        <SvgIcon
          SvgComponent={LocationPin}
          hue="danger"
          shade="fontColor"
          size="lg"
        />
        Stores
      </IconsBox>
      <IconsBox as="div" onClick={() => setCompareDrawerOpen(true)}>
        {compareCount > 0 && <span className="notify">{compareCount}</span>}
        <SvgIcon
          SvgComponent={ListCheck}
          hue="danger"
          shade="fontColor"
          size="lg"
        />
        Compare
      </IconsBox>
      <IconsBox
        as="div"
        onClick={(e) => {
          setCartDrawerOpen(true)
          e.preventDefault() // Do not perform default "LinkWrapper" navigate
        }}
      >
        <CartIcon />
      </IconsBox>
    </IconsNav>
  )
}

const IconsNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`

const IconsBox = styled(LinkWrapper)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  position: relative;
  text-align: center;
  color: #fff;
  min-width: 35px;
  span.notify {
    color: #fff;
    text-align: center;
    position: absolute;
    top: 14px;
    right: 10px;
    background: ${({ theme }) => theme.colors.ruby["060"]};
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  padding: 10px;
  transition: all 200ms ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
`

export default memo(IconSection)
