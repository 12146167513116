import React from "react"

const IconChevronRight = ({ fill }) => (
  <svg
    width="7px"
    height="13px"
    viewBox="0 0 7 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon" transform="translate(-9.000000, -6.000000)" fill={fill}>
        <path
          d="M9.28946521,6.09611011 C9.41758533,5.9679633 9.62533325,5.9679633 9.75348007,6.09611011 L15.5243861,11.8669628 C15.6525329,11.9951363 15.6525329,12.2028842 15.5243861,12.331031 L9.75348007,18.1019103 C9.62533325,18.2300305 9.41758533,18.2300305 9.28946521,18.1019103 L9.09611011,17.908582 C8.9679633,17.7804351 8.9679633,17.5726605 9.09611011,17.4445404 L14.4416136,12.0990102 L9.09611011,6.75348007 C8.9679633,6.62533325 8.9679633,6.41755863 9.09611011,6.28943851 L9.28946521,6.09611011 Z"
          id=""
        ></path>
      </g>
    </g>
  </svg>
)

export default IconChevronRight
