import React from "react"

const IconListCheck = ({ fill }) => (
  <svg width="11" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.19 1.75c.72 0 1.31.59 1.31 1.31v9.63c0 .72-.59 1.31-1.31 1.31H1.3C.6 14 0 13.41 0 12.69V3.06c0-.72.59-1.31 1.31-1.31h2.43a1.53 1.53 0 113.03 0h2.42zM5.25.87a.65.65 0 100 1.32.65.65 0 100-1.32zM9.62 12.7V3.06c0-.24-.2-.44-.43-.44H7.87v.55c0 .18-.14.33-.32.33h-4.6a.33.33 0 01-.33-.33v-.55h-1.3c-.25 0-.45.2-.45.44v9.63c0 .24.2.43.44.43H9.2c.24 0 .43-.2.43-.43zM3.06 8.97a.65.65 0 110 1.31.65.65 0 110-1.31zm4.6.22c.12 0 .21.1.21.22v.43c0 .12-.1.22-.21.22H4.59a.22.22 0 01-.22-.22v-.43c0-.12.1-.22.22-.22h3.07zm-4.2-1.8a.14.14 0 01-.21 0L2.23 6.37a.15.15 0 010-.2l.35-.35a.15.15 0 01.2 0l.57.57 1.3-1.29a.15.15 0 01.21 0l.35.35c.05.05.05.15 0 .2L3.45 7.4zm4.2-.83c.12 0 .21.1.21.22v.44c0 .12-.1.22-.21.22h-3l.87-.88h2.13z"
      fill={fill}
    />
  </svg>
)

export default IconListCheck
