import React from "react"

const IconChevronLeft = ({ fill }) => (
  <svg
    width="7px"
    height="13px"
    viewBox="0 0 7 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon" transform="translate(-9.000000, -6.000000)" fill={fill}>
        <path
          d="M15.331031,18.1018903 C15.2029109,18.2300371 14.995163,18.2300371 14.8670162,18.101917 L9.09611011,12.3310377 C8.9679633,12.2028642 8.9679633,11.9951162 9.09611011,11.8669694 L14.8670162,6.09609009 C14.995163,5.96796997 15.2029109,5.96796997 15.331031,6.09609009 L15.5243861,6.28941848 C15.6525329,6.4175653 15.6525329,6.62533992 15.5243861,6.75346004 L10.1788826,12.0989902 L15.5243861,17.4445204 C15.6525329,17.5726672 15.6525329,17.7804418 15.5243861,17.9085619 L15.331031,18.1018903 Z"
          id=""
        ></path>
      </g>
    </g>
  </svg>
)

export default IconChevronLeft
