import React from "react"

const IconTrash = ({ fill }) => (
  <svg width="13" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.1 11.81a.22.22 0 01-.23-.22V4.16c0-.12.1-.22.22-.22h.44c.12 0 .22.1.22.22v7.43c0 .12-.1.22-.22.22H8.1zm-4.38 0a.22.22 0 01-.22-.22V4.16c0-.12.1-.22.22-.22h.44c.12 0 .21.1.21.22v7.43c0 .12-.1.22-.21.22h-.44zm8.31-10.06c.12 0 .22.1.22.22v.44c0 .12-.1.21-.22.21h-.66V12.7c0 .72-.58 1.31-1.3 1.31H2.18c-.73 0-1.32-.59-1.32-1.31V2.62H.22A.22.22 0 010 2.41v-.44c0-.12.1-.22.22-.22h2.84L3.98.53C4.2.23 4.67 0 5.03 0h2.19c.36 0 .83.24 1.05.53l.92 1.22h2.84zm-7.35-.7l-.52.7h3.93l-.52-.7a.5.5 0 00-.35-.18H5.03a.5.5 0 00-.35.18zm5.82 11.64V2.62H1.75V12.7c0 .24.2.43.44.43h7.87c.24 0 .44-.2.44-.43zm-4.6-.88a.22.22 0 01-.21-.22V4.16c0-.12.1-.22.22-.22h.43c.12 0 .22.1.22.22v7.43c0 .12-.1.22-.22.22h-.43z"
      fill={fill}
    />
  </svg>
)

export default IconTrash
