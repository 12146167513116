import React, { useState } from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"
import { GatsbyImage } from "gatsby-plugin-image"
import { CreateLocalLink } from "../../../../lib/util"
import SvgIcon from "../../../atoms/icons/svg-icon"
import ArrowRight from "../../../atoms/icons/files/arrow-right.jsx"

const FurnitureMenu = ({ links, cta }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const [activeSubMenu, setActiveSubMenu] = useState("")

  const fluidImage =
    cta.backgroundImage.imageFile.localFile.childImageSharp.gatsbyImageData
  return (
    <Wrap>
      <GatsbyImage
        image={fluidImage}
        className="image"
        alt="furniture-menu-image"
      />
      <Links>
        {links.map((node, i) => {
          const nodeUrl = CreateLocalLink(node.url)
          return node.childItems ? (
            <Stack key={i}>
              {node.url === "#" ? (
                <div className="column-heading">{node.label}</div>
              ) : (
                <Link to={nodeUrl} className="column-heading">
                  {node.label}
                  {node.url !== "#" && (
                    <SvgIcon SvgComponent={ArrowRight} hue="ruby" shade="060" />
                  )}
                </Link>
              )}
              {node.childItems.map((childnode, k) => {
                const childnodeUrl = CreateLocalLink(childnode.url)
                return (
                  <>
                    <Link
                      to={childnodeUrl}
                      as={childnodeUrl ? "a" : "div"}
                      key={i + "-" + k}
                      active={
                        subMenuOpen && activeSubMenu === k ? "true" : "false"
                      }
                      className="sub"
                      onMouseEnter={() => {
                        setSubMenuOpen(true)
                        setActiveSubMenu(i + "-" + k)
                      }}
                      onMouseLeave={() => setSubMenuOpen(false)}
                    >
                      {childnode.label}
                    </Link>

                    {childnode.childItems && (
                      <Stack
                        key={i + "-" + k + "-stack"}
                        className={`${
                          subMenuOpen === true &&
                          activeSubMenu === i + "-" + k &&
                          "submenu-active"
                        } grandstack`}
                        onMouseEnter={() => setSubMenuOpen(true)}
                        onMouseLeave={() => setSubMenuOpen(false)}
                      >
                        {childnode.childItems &&
                          childnode.childItems.map((grandChild, o) => {
                            const grandchildnodeUrl = CreateLocalLink(
                              grandChild.url
                            )
                            return (
                              <Link
                                to={grandchildnodeUrl}
                                as={grandchildnodeUrl ? "a" : "div"}
                                key={o}
                                className="sub grandsub"
                                onClick={() => setSubMenuOpen(false)}
                              >
                                {grandChild.label}
                              </Link>
                            )
                          })}
                      </Stack>
                    )}
                  </>
                )
              })}
            </Stack>
          ) : (
            <Link
              to={nodeUrl}
              as={nodeUrl ? Link : "div"}
              key={i}
              className="column-heading"
            >
              {node.label}
            </Link>
          )
        })}
      </Links>
    </Wrap>
  )
}

export default FurnitureMenu

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  // grid-template-columns: repeat(5, 1fr);
  padding: 40px;
  grid-gap: 50px;
  min-height: 400px;
  img {
    border-radius: 3px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: 1fr;
    .image {
      display: none;
    }
  }
`

const Stack = styled.div`
  position: relative;
  a.sub {
    font-weight: 100;
    font-size: 15px;
    padding: 6px 0;
    color: #b6c9e3;
    transition: all 200ms ease-in-out;
    &:hover {
      color: #fff;
    }
  }
  &.grandstack {
    position: absolute;
    z-index: 999;
    background-color: #162232;
    left: 100%;
    top: -40px;
    bottom: -40px;
    padding: 25px 15px;
    transition: all 200ms ease-in-out;
    display: none;
    width: 220px;
    &.submenu-active {
      display: block;
    }
  }
`

const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  a {
    display: block;
    color: white;
  }
`
