import React from "react"
import styled from "styled-components"
import SvgIcon from "../../atoms/icons/svg-icon"
import Facebook from "../../atoms/icons/files/social-facebook.jsx"
import Twitter from "../../atoms/icons/files/social-twitter.jsx"
import YouTube from "../../atoms/icons/files/social-youtube.jsx"
import Instagram from "../../atoms/icons/files/social-instagram.jsx"
import Pinterest from "../../atoms/icons/files/social-pinterest.jsx"
import LinkedIn from "../../atoms/icons/files/social-linkedin.jsx"
import { graphql, useStaticQuery } from "gatsby"

const socialIcons = {
  facebook: Facebook,
  twitter: Twitter,
  youtube: YouTube,
  instagram: Instagram,
  pinterest: Pinterest,
  linkedin: LinkedIn,
}

const SocialIconsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 56px);
  justify-items: center;
  @media (max-width: 380px) {
    grid-template-columns: repeat(6, 1fr);
  }
`
const SocialLink = styled.a`
  padding: 12px;
  display: inline-block;
  transition: all 200ms ease-in-out;
  svg #icon {
    transition: fill 100ms ease-in-out;
  }
  &:hover {
    svg #icon {
      fill: #fff;
    }
    &.youtube {
      background: #ff0000;
    }
    &.instagram {
      background-image: linear-gradient(
        to top right,
        #fcaf45,
        #f56040,
        #e1306c
      );
    }
    &.twitter {
      background: #1da1f2;
    }
    &.pinterest {
      background: #bd081c;
    }
    &.facebook {
      background: #3c5a99;
    }
    &.linkedin {
      background: #0077b5;
    }
  }
  @media (max-width: 380px) {
    padding: 8px;
  }
`

const SocialIcons = () => {
  const data = useStaticQuery(graphql`
    {
      acfOptions: localWpGraphQlTmwGeneralSettings {
        tmw_settings {
          socialLinks {
            channels {
              title
              url
            }
          }
        }
      }
    }
  `)

  const { channels } = data.acfOptions.tmw_settings.socialLinks

  return (
    <SocialIconsWrapper>
      {channels.map((channel) => (
        <SocialLink
          key={channel.title}
          href={channel.url}
          className={channel.title}
          rel="noopener nofollow"
          target="_blank"
        >
          <SvgIcon
            SvgComponent={socialIcons[channel.title]}
            hue="tusk"
            shade="100"
            size="md"
          />
        </SocialLink>
      ))}
    </SocialIconsWrapper>
  )
}

export default SocialIcons
