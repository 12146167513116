import React from "react"

const LocationPin = ({ fill }) => (
  <svg width="14" height="17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2.62a2.63 2.63 0 110 5.26 2.63 2.63 0 010-5.26zM7 7a1.75 1.75 0 100-3.5A1.75 1.75 0 007 7zm0-7c2.9 0 5.25 2.35 5.25 5.25 0 2.12-.74 2.7-4.71 8.47a.66.66 0 01-1.08 0C2.49 7.96 1.75 7.37 1.75 5.25 1.75 2.35 4.1 0 7 0zm0 12.96c3.8-5.51 4.37-5.95 4.37-7.71A4.35 4.35 0 007 .87a4.35 4.35 0 00-4.38 4.38c0 1.76.57 2.2 4.38 7.7zm3.06-.86c2.33.35 3.94 1.1 3.94 1.96 0 1.21-3.13 2.19-7 2.19s-7-.98-7-2.19c0-.86 1.6-1.6 3.94-1.96v.84c-1.7.23-2.85.65-2.85 1.12 0 .73 2.65 1.32 5.91 1.32 3.26 0 5.9-.6 5.9-1.32 0-.47-1.13-.89-2.84-1.12v-.84z"
      fill={fill}
    />
  </svg>
)

export default LocationPin
