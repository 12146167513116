import React from "react"
import colors from "../../colors"
import styled from "styled-components"

const SvgIcon = ({
  SvgComponent = false,
  hue = "midnight",
  shade = "060",
  size = "sm",
  className,
  onClick,
}) => {
  // Get Fill
  let fill = colors[hue][shade]
  // Get Size, default = sm
  let dimensions = "24px"
  let scale = "1"
  switch (size) {
    case "xs":
      dimensions = "16px"
      scale = "1"
      break
    case "md":
      dimensions = "32px"
      scale = "1.3"
      break
    case "lg":
      dimensions = "45px"
      scale = "2"
      break
    case "xl":
      dimensions = "60px"
      scale = "3"
      break
  }

  if (!SvgComponent) {
    return null
  }
  return (
    <IconContainer className={`icon ${className}`} onClick={onClick}>
      <IconWrapper dimensions={dimensions} scale={scale}>
        <SvgComponent fill={fill} />
      </IconWrapper>
    </IconContainer>
  )
}

export default SvgIcon

const IconContainer = styled.span`
  display: inline-block;
`
const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: ${(props) => props.dimensions};
  height: ${(props) => props.dimensions};
  svg {
    transform: scale(${(props) => props.scale}, ${(props) => props.scale});
    #icon {
      transition: all 200ms ease-in-out;
    }
  }
`
