import React from "react"

const BLANK = ({ fill }) => (
  <svg width="20" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8 7.7c.11.09.2.28.2.43 0 .12-.06.28-.13.37l-.35.44c-.1.11-.29.2-.43.2a.63.63 0 01-.36-.13l-.95-.8v6.66c0 .62-.5 1.13-1.11 1.13h-4.45c-.61 0-1.1-.51-1.11-1.14v-3.72H8.89v3.72c0 .63-.5 1.14-1.11 1.14H3.33c-.6 0-1.1-.51-1.1-1.14V8.2l-.96.8a.63.63 0 01-.36.14.62.62 0 01-.42-.2L.13 8.5A.68.68 0 010 8.13c0-.15.09-.34.2-.44L8.75.46C9.05.21 9.61 0 10 0s.95.2 1.24.46l4.87 4.11v-2c0-.16.13-.28.28-.28h1.1c.16 0 .29.12.29.28v3.41L19.8 7.7zm-3.7 6.59l.01-7.5L10 1.63 3.89 6.79v7.5h3.33v-3.72c0-.63.5-1.14 1.11-1.14h3.34c.6 0 1.1.51 1.1 1.14v3.72h3.33z"
      fill={fill}
    />
  </svg>
)

export default BLANK
