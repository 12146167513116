import React from "react"
import styled from "styled-components"

const BlurEffect = styled.div`
  transition: 0.1s all ease-in-out;
  &.blur {
    filter: blur(4px);
    opacity: 0.5;
  }
`

const OuterBox = styled.div`
  display: block;
  position: relative;
`

const LoaderContainer = styled.div`
  margin: 50px;
  position: sticky;
  top: 50vh;

  align-items: center;
`

const LoaderSkeleton = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  -webkit-justify-content: start;
  h3 {
    color: ${(props) => (props.shade === "light" ? "white" : null)};
  }
  .lds-ellipsis {
    position: relative;
    width: 64px;
    height: 32px;
    margin: auto;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 16px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${(props) => (props.shade === "light" ? "#FFFFFF" : "#19283b")};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
`

//This BlurLoader requires that all parent elements be overflow:visible
const BlurLoader = ({
  message = "Loading",
  loading = false,
  shade = "dark",
  children,
}) => {
  loading = false
  // console.log("blurLoader index.jsx Loading is: " + loading)
  return (
    <OuterBox>
      <BlurEffect className={loading && "blur"}>{children}</BlurEffect>
      {loading && (
        <LoaderSkeleton shade={shade}>
          <LoaderContainer>
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
            <h3>{message}</h3>
          </LoaderContainer>
        </LoaderSkeleton>
      )}
    </OuterBox>
  )
}
export default BlurLoader
