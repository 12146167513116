export default {
  white: {
    "000": "#fff",
  },
  midnight: {
    "000": "#2D475D",
    "020": "#283F53",
    "040": "#223247",
    "060": "#1A293C",
    "080": "#162232",
    100: "#071322",
  },
  tusk: {
    "000": "#F0F1F3",
    "020": "#DEE3E8",
    "040": "#CFD5DB",
    "060": "#BAC0C5",
    "080": "#9BA5AE",
    100: "#77848E",
  },
  ruby: {
    "000": "#FE7C7F",
    "020": "#FD585C",
    "040": "#FD4449",
    "060": "#FD3237",
    "080": "#ED1C24",
    100: "#D91A1F",
  },
  ocean: {
    "000": "#0BB1F4",
    "020": "#0AA9EB",
    "040": "#099FDC",
    "060": "#0998D2",
    "080": "#0988BB",
    100: "#037CAC",
  },
  gold: {
    "000": "#FFCB70",
    "020": "#FFC35C",
    "040": "#FFBC47",
    "060": "#FFB433",
    "080": "#FFAC1D",
    100: "#F7A416",
  },
  grass: {
    "000": "#03FC68",
    "020": "#03F264",
    "040": "#03E85F",
    "060": "#02DE5B",
    "080": "#02D558",
    100: "#00C851",
  },
  sky: {
    "000": "#A6DEF2",
    "020": "#8BD4EE",
    "040": "#79CDEC",
    "060": "#6CC8EA",
    "080": "#60C4E8",
    100: "#48B6DD",
  },
  bg: {
    "000": "#F6F6F6",
  },
  success: {
    fontColor: "#fff",
    light: "#19CD67",
    dark: "#15C05F",
  },
  info: {
    fontColor: "#fff",
    light: "#1FB6FF",
    dark: "#1CABF1",
  },
  warning: {
    fontColor: "rgba(0,0,0,0.7)",
    light: "#FFC82C",
    dark: "#F3BE26",
  },
  danger: {
    fontColor: "#fff",
    light: "#FF4949",
    dark: "#EF3C3C",
  },
}
