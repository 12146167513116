import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"

import PushNotificationContext from "../../context/PushNotification/PushNotificationContext"
import BellIcon from "../../images/icons/bell-notification"
import CloseIcon from "../../images/icons/close-notification"

const CustomPushPermissionRequestPopup = ({ className = "" }) => {
  const {
    userAcceptedNotifications,
    userRejectedNotifications,
    showCustomPushPermissionRequestPopup,
  } = useContext(PushNotificationContext)

  const [hide, setHide] = useState(true)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let mounted = true
    if (showCustomPushPermissionRequestPopup != open) {
      if (showCustomPushPermissionRequestPopup) {
        setHide(false)
        setTimeout(() => {
          if (mounted) {
            setOpen(true)
          }
        }, 500)
      } else {
        setOpen(false)
        setTimeout(() => {
          if (mounted) {
            setHide(true)
          }
        }, 500)
      }
    }

    return () => {
      mounted = false
    }
  }, [showCustomPushPermissionRequestPopup])

  return (
    <PopupContainerStyling className={className} hide={hide} open={open}>
      <PopupHeaderStyling>
        <StyledH2>We would like to send you notifications</StyledH2>
        <CloseIconWrapperStyling onClick={userRejectedNotifications}>
          <CloseIcon />
        </CloseIconWrapperStyling>
      </PopupHeaderStyling>
      <PopupContentStyling>
        <BellIconWrapperStyling>
          <BellIcon />
        </BellIconWrapperStyling>
        <p>
          Enable notifications to get the best news on sales and special offers
        </p>
        <StyledButton onClick={userAcceptedNotifications}>Allow</StyledButton>
        <StyledButton onClick={userRejectedNotifications}>
          No Thanks
        </StyledButton>
      </PopupContentStyling>
    </PopupContainerStyling>
  )
}

export default CustomPushPermissionRequestPopup

// ===============
//     STYLES
// ===============

const PopupContainerStyling = styled.div`
  display: ${({ hide }) => (hide ? "none" : "block")};
  transform: ${({ open }) => (open ? "translateY(10px)" : "translateY(-100%)")};
  transition: transform 0.3s ease-out;
  position: fixed;
  box-shadow: 0px 3px 20px #00000033;
  border-radius: 3px;
  top: 0;
  left: calc(50% - 620px);
  z-index: 100000000;
  background-color: white;
  color: black;
  max-width: 480px;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -8px;
    left: 10px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #223347;
    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 1280px) {
    left: 20px;
  }

  @media (max-width: 767px) {
    max-width: calc(100% - 40px);
    top: unset;
    bottom: 20px;
    transform: ${({ open }) => (open ? "translateY(0px)" : "translateY(115%)")};
  }
`

const StyledH2 = styled.h2`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #223347;
  color: white;
  font-size: 0.95rem;
  text-align: center;
  width: 100%;
  padding: 7px 0;
`

const PopupContentStyling = styled.div`
  padding: 15px 40px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  p {
    width: 82%;
    color: #071322;
  }

  @media (max-width: 767px) {
    padding: 15px 20px 20px;
  }
`

const StyledButton = styled.button`
  display: inline-block;
  -webkit-appearance: none;
  padding: 9px 0;
  width: 100px;
  text-transform: uppercase;
  border: none;
  background-color: #ed1c24;
  color: white;
  border-radius: 2px;
  outline: none;
  margin-top: 15px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  &:hover {
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: #cb2a2d;
  }
  & + & {
    background-color: transparent;
    margin-left: 10px;
    color: #071322;
    &:hover {
      background-color: #223347;
      color: white;
    }
  }
`

const BellIconWrapperStyling = styled.div`
  width: 18%;
`
const CloseIconWrapperStyling = styled.span`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: calc(50% - 13px);
`
const PopupHeaderStyling = styled.div`
  position: relative;
  height: min-content;
`
