import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Logo = ({ style = {}, imgStyle = {} }) => (
  <StaticImage
    src="../../images/logo.png"
    layout="constrained"
    alt="TheMattressWarehouseLogo"
    objectFit="contain"
    loading="eager"
    quality={90}
    objectPosition="50% 50%"
    imgStyle={{ width: "auto", ...imgStyle }}
    style={{ ...style }}
    placeholder="none"
  />
)

export default Logo
