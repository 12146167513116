import React, { memo, useEffect } from "react"

import HeaderSkinny from "../../molecules/headerSkinny"
import HeaderMain from "../../molecules/headerMain"
import MainNavigation from "../../molecules/mainNavigation"

const DesktopHeader = () => {
  return (
    <div className="desktop">
      <HeaderSkinny />
      <HeaderMain />
      <MainNavigation />
    </div>
  )
}
export default memo(DesktopHeader)
