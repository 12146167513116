import React, { useContext } from "react"
import CartContext from "../../context/CartContext"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

import styled from "styled-components"
import Counter from "../ProductSingle/Counter"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
// import Cross from "../../zzz/atoms/icons/files/cross.jsx"
import Trash from "../../zzz/atoms/icons/files/trash.jsx"
import { shippingClassGet, formatCurrencyInt, slugify } from "../../lib/util"

const CartDrawerItems = ({
  isInStoreFrontendState = false,
  setMessage = () => {},
  cartContentsToShow = [],
}) => {
  const { setCartDrawerOpen, removeFromCart, updateInCart } =
    useContext(CartContext)

  // Remove an item from cart
  const removeItem = (product) => {
    console.log("Removing: ", product)
    setMessage("")
    removeFromCart(product).catch((error) => {
      if (error && error.message) {
        setMessage(error.message)
      } else {
        setMessage("Failed to complete action")
      }
    })
  }

  // Update an item with a new quantity
  const updateItem = (product, quantity) => {
    console.log("Updating: ", product)
    setMessage("")
    updateInCart(product, quantity).catch((error) => {
      if (error && error.message) {
        setMessage(error.message)
      } else {
        setMessage("Failed to complete action")
      }
    })
  }

  return (
    <Items>
      {cartContentsToShow.map((item, key) => {
        // Get all the shipping info for this item according to shipping class
        const shippingInfo = shippingClassGet(item?.shippingClassForLocation)
        return (
          <Card key={key}>
            {!item?.shippingClassForLocation ? null : (
              <Badge
                backgroundColour={shippingInfo.backgroundColor}
                textColour={shippingInfo.textColor}
              >
                {shippingInfo.text}
              </Badge>
            )}
            {item?.wc_bogof_cart_rule && (
              <Badge
                backgroundColour="#0998d2"
                textColour="white"
                offset={
                  typeof item?.shippingClassForLocation == "string" && "33px"
                }
              >
                PROMO
              </Badge>
            )}
            <img src={item?.product_image} />
            <div className="text">
              <Link
                onClick={() => setCartDrawerOpen(false)}
                to={`/product/${slugify(item?.product_title)}`}
              >
                {item?.productName}
              </Link>
              {isInStoreFrontendState && (
                <a style={{ fontWeight: "300", fontSize: "15px" }}>
                  {item?.productSku}
                </a>
              )}

              {!item?.shippingClassForLocation ? null : (
                <Shipping>
                  {`${shippingInfo.description} ${shippingInfo.delay} business days`}
                </Shipping>
              )}
              <Price>
                {item?.line_subtotal > 0 && !item?.wc_bogof_cart_rule
                  ? "R" + formatCurrencyInt.format(item?.line_subtotal)
                  : "FREE"}
              </Price>
            </div>
            <Right className="right">
              <Counter
                onChange={(quantity) => {
                  updateItem(item, quantity)
                }}
                min={1}
                value={item?.quantity}
                max={99}
                disabled={!!item?.wc_bogof_cart_rule}
                isReadOnly
              />
            </Right>
            {!item?.wc_bogof_cart_rule && (
              <RemoveButton onClick={() => removeItem(item)}>
                <SvgIcon
                  // SvgComponent={Cross}
                  SvgComponent={Trash}
                  hue="tusk"
                  shade="100"
                  size="sm"
                />
              </RemoveButton>
            )}
          </Card>
        )
      })}
    </Items>
  )
}

export default CartDrawerItems

const Items = styled.div`
  background: #f0f1f3;
  padding: 30px;
`

const Right = styled.div`
  display: flex;
  align-items: flex-end;
  > div {
    height: 40px;
  }
`

const Price = styled.div`
  font-weight: bold;
`

const Shipping = styled.p`
  font-weight: lighter;
  font-style: italic;
  font-size: 0.85rem;
`

const RemoveButton = styled.div`
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  position: absolute;
  top: -12px;
  right: -14px;
  background: #cfd5db;
  border-radius: 200px;
  &:hover {
    transform: translateY(-2px);
  }
`

const Card = styled.div`
  box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.41);
  border-radius: 4px;
  background: white;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr 100px;
  grid-gap: 30px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
    .text {
      text-align: center;
    }
    .right {
      justify-content: center;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      font-size: 18px;
      font-weight: 500;
    }
    div {
      font-weight: 900;
    }
  }
`

const Badge = styled.div`
  text-transform: uppercase;
  border-radius: 2px;
  background: ${(props) => props.backgroundColour || "#FCDBA2"};
  color: ${(props) => props.textColour || "#DA8D08"};
  padding: 0px 10px;
  position: absolute;
  font-size: 12px;
  top: ${(props) => props.offset || "10px"};
  right: 10px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    top: 10px;
  }
`
