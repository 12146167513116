import React from "react"

const FeatherCheck = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.466"
    height="7.43"
    viewBox="0 0 10.466 7.43"
  >
    <path
      style={{
        fill: "none",
        stroke: fill,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M15.052,9,8.829,15.223,6,12.394"
      transform="translate(-5.293 -9.293)"
    />
  </svg>
)

export default FeatherCheck
