import React from "react"

const SocialTwitter = ({ fill }) => (
  <svg width="16" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.34 3.25A9.27 9.27 0 0113.28 8a9.02 9.02 0 01-8.25 5A9.1 9.1 0 010 11.53a6.5 6.5 0 004.87-1.37c-.72 0-1.36-.22-1.92-.64A3.26 3.26 0 011.81 7.9l.6.03c.29 0 .58-.03.87-.1A3.3 3.3 0 011.41 6.7c-.5-.6-.75-1.3-.75-2.07v-.06c.45.27.94.42 1.46.44-.43-.31-.79-.7-1.06-1.19A3.15 3.15 0 011.12.6a9.05 9.05 0 006.75 3.44c-.04-.25-.06-.5-.06-.75A3.25 3.25 0 019.44.44c.5-.3 1.04-.44 1.62-.44.48 0 .92.1 1.33.28.4.19.77.44 1.08.75.75-.14 1.45-.4 2.1-.78a3.26 3.26 0 01-1.45 1.81A7.05 7.05 0 0016 1.53a7.21 7.21 0 01-1.66 1.72z"
      fill={fill}
    />
  </svg>
)

export default SocialTwitter
