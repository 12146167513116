import React from "react"

const PenSquare = ({ fill }) => (
  <svg width="14" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.812.625c.725 0 1.313.588 1.313 1.313v9.625c0 .724-.588 1.312-1.313 1.312H2.187a1.313 1.313 0 01-1.312-1.312V1.938c0-.725.588-1.313 1.312-1.313h9.625zm.438 10.938V1.938a.439.439 0 00-.438-.438H2.187a.439.439 0 00-.437.438v9.625c0 .24.197.437.437.437h9.625c.241 0 .438-.197.438-.437zm-1.835-8.868l.64.64c.427.43.427 1.12 0 1.547l-6.024 6.024-1.95.219a.41.41 0 01-.453-.454l.216-1.952 6.024-6.024a1.096 1.096 0 011.547 0zM4.63 10.07l4.446-4.452-.948-.949-4.45 4.449-.117 1.07 1.07-.118zm5.808-5.806a.22.22 0 000-.309l-.64-.64a.22.22 0 00-.309 0l-.738.739.949.949.738-.739z"
      fill={fill}
    />
  </svg>
)

export default PenSquare
