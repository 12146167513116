/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo, useRef } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import PropTypes from "prop-types"
import CompareLocation from "./CompareLocation"
import Cookies from "js-cookie"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ListCheck from "../../zzz/atoms/icons/files/list-check.jsx"
import Cross from "../../zzz/atoms/icons/files/cross.jsx"
import Check from "../../zzz/atoms/icons/files/check.jsx"
import { formatMoney } from "../../lib/util"
import { Responsive, windowSizes } from "../../utils/responsive"
import MobileWrapper from "./MobileWrapper"
import Modal from "react-modal"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

export const isBrowser = () => typeof window !== "undefined"

export const getCompareList = () => {
  if (!isBrowser()) return

  if (window.localStorage.getItem("itemsToCompare") == null) {
    return []
  } else {
    let compareList = []
    const compareCookieString = Cookies.get("compare")
    if (!compareCookieString) return []

    try {
      compareList = JSON.parse(compareCookieString)
    } catch {
      return []
    }
    return compareList
  }
}

const setUrlQueryId = (search_params, url, list) => {
  search_params.set("queryId", list)
  url.search = search_params.toString()
}

// Build a link to the compare page including the products in the URL
export const getCompareLink = () => {
  if (!isBrowser()) return

  const currentList = getCompareList()
  if (currentList.length > 0) {
    var search_params = new URLSearchParams("")
    var url = new URL(window.location.href)
    setUrlQueryId(search_params, url, currentList)
    return "/compare-products/" + url.search
  } else return "/compare-products/"
}

const persistItems = (items) => {
  window.localStorage.setItem("itemsToCompare", JSON.stringify(items))
  Cookies.set("compare", JSON.stringify(items))
}

export const addToCompare = (id) => {
  if (isBrowser()) {
    var url = new URL(window.location.href)
    var search_params = new URLSearchParams(url.search)
    var currentList = []
    const compareList = getCompareList()
    if (compareList.length !== 0) {
      currentList = compareList
    }
    if (!currentList.some((checkId) => checkId == id)) {
      const newList = [...currentList, id.toString()]
      setUrlQueryId(search_params, url, newList)
      persistItems(newList)
    }
  }
}

export const removeFromCompare = (id) => {
  if (isBrowser()) {
    const currentList = getCompareList()
    if (currentList.some((chId) => chId == id)) {
      const newList = currentList.filter((item) => item != id)
      if (newList.length == 0) {
        window.localStorage.removeItem("itemsToCompare")
        Cookies.remove("compare")
      } else persistItems(newList)
    } else console.log("item is not in comparelist")
  }
}

export const clearCompareList = () => {
  if (!isBrowser()) return
  window.localStorage.removeItem("itemsToCompare")
  Cookies.remove("compare")
  navigate("/compare-products/")
}

const CompareProducts = ({ search, location }) => {
  const { queryId } = search

  const data = useStaticQuery(COMPARE_GRAPHQL)
  const productList = data.allWcProducts.nodes

  const onFocus = () => {
    if (isBrowser()) {
      var url = new URL(window.location.href)
      var queryString = url.search
      var search_params = new URLSearchParams(queryString)
      if (queryId != undefined) {
        const currentCompareList = getCompareList()
        if (currentCompareList) {
          const currentCompareListStr = currentCompareList.join()
          if (queryId !== currentCompareListStr) {
            const new_url = getCompareLink()
            navigate(new_url)
          }
        }
      } else {
        const currentPage = getCompareLink()
        navigate(currentPage)
      }
    }
  }

  // populate products to compare
  const productsToCompare = (queryIdList = []) => {
    queryIdList = queryId ? queryId.split(",") : false
    let compareList = getCompareList()

    if (queryIdList && queryIdList.length == 1) {
      return productList.filter(
        (compareItem) => parseInt(queryIdList) === compareItem.wordpress_id
      )
    } else if (queryIdList && queryIdList.length > 1) {
      return productList.filter(
        (compareItem) =>
          queryIdList.indexOf(compareItem.wordpress_id.toString()) !== -1
      )
    } else if (!queryIdList && compareList && compareList.length > 0) {
      return productList.filter(
        (item) =>
          compareList instanceof Array &&
          compareList.indexOf(item.wordpress_id) !== -1
      )
    } else return []
  }

  const handleRemoveEvent = (id) => {
    removeFromCompare(id)
    navigate(getCompareLink())
  }

  const getMin = (prices) => {
    return Math.round(Math.min(...prices) * 1.15)
  }
  const getMax = (prices) => {
    return Math.round(Math.max(...prices) * 1.15)
  }
  const getMinMax = (prices) => {
    return [getMin(prices), getMax(prices)]
  }

  const getMatPrice = (compareProduct) => {
    if (
      compareProduct.bundled_items &&
      compareProduct.bundled_items.length > 1 &&
      compareProduct.bundled_items[0].wordpress_id != null
    ) {
      const bundleItems = compareProduct.bundled_items
      const bundleItemIds = bundleItems.map((item) => item.wordpress_id)

      let matId = bundleItemIds[0]
      let matItemArr = productList.filter((pr) => {
        return pr.wordpress_id == matId
      })
      if (!matItemArr || !matItemArr.length) {
        matId = bundleItemIds[1]
        matItemArr = productList.filter((pr) => {
          return pr.wordpress_id == matId
        })
      }
      const matItem = matItemArr[0]
      const [minMatPrice] = getMinMax(getPrices(matItem))

      if (bundleItemIds[1] != undefined) {
        const baseId = bundleItemIds[1]
        const baseItem = productList.filter((pr) => pr.wordpress_id == baseId)
        if (baseItem.length && baseItem[0].product_variations != undefined) {
          const [minBasePrice] = getMinMax(getPrices(baseItem[0]))
          return "From: " + formatMoney(minMatPrice + minBasePrice)
        }
      }
      return "From: " + formatMoney(minMatPrice)
    } else {
      return "From: " + formatMoney(getMin(getPrices(compareProduct)))
    }
  }

  const getPrices = (product) => {
    if (product.product_variations && product.product_variations.length > 0) {
      return product.product_variations.map((vr) => vr.price)
    } else {
      return [product.price]
    }
  }

  useEffect(() => {
    if (typeof window != "undefined") {
      window.addEventListener("focus", onFocus)
      if (isBrowser()) {
        var url = new URL(window.location.href)
        var queryString = url.search
        var search_params = new URLSearchParams(queryString)
        const new_url = "/compare-products/?" + search_params.toString()
        if (queryId != undefined) {
          const currentCompareList = getCompareList()
          let currentCompareListStr = ""
          if (Array.isArray(currentCompareList)) {
            currentCompareListStr = currentCompareList.join()
          }
          if (queryId !== currentCompareListStr) {
            const queryIdList = queryId.split()
            clearCompareList()
            queryIdList.forEach((item) => {
              addToCompare(item)
            })
            navigate(new_url)
          }
        } else {
          const currentPage = getCompareLink()
          navigate(currentPage)
        }
      }
      return () => window.removeEventListener("focus", onFocus)
    }
  }, [])

  const LIMIT = 4
  const products = productsToCompare()
  const [amountInvalid, setAmountInvalid] = useState(
    products.length > LIMIT || products.length < 1
  )
  const [filteredIds, setFilteredIds] = useState([])

  const filteredItems = useMemo(() => {
    if (products.length <= LIMIT) return productsToCompare()

    return productsToCompare(filteredIds).filter(({ wordpress_id }) =>
      filteredIds.some((id) => id === wordpress_id)
    )
  }, [filteredIds, products])

  const handleToggle = (id) => {
    if (filteredIds.includes(id)) return setFilteredIds(remove(id, filteredIds))
    else if (filteredIds.length === LIMIT) return
    setFilteredIds(add(id, filteredIds))
  }

  const add = (item, list) => [...list, item]
  const remove = (item, list) => list.filter((a) => a !== item)

  const handleRemove = (id) => {
    console.log("remove idi", id)
    setFilteredIds(remove(id, filteredIds))
    handleRemoveEvent(id)
  }

  useEffect(() => {
    if (products.length < 5 && products.length > 0) {
      setAmountInvalid(false)
    } else {
      setAmountInvalid(true)
    }
  }, [products])

  const scrollRef = React.useRef()

  const [open, setOpen] = useState(false)
  const copyRef = useRef()
  const [copyVal, setCopyVal] = useState(location.href)
  useEffect(() => setCopyVal(location.href), [location.href])

  const copyToClipboard = () => {
    copyRef.current.select()
    document.execCommand("copy")
    setCopyVal("Successfully copied to clipboard!")
    setTimeout(() => {
      setCopyVal(location.href)
      setOpen(false)
    }, 1500)
  }

  const noItems = products.length === 0

  return (
    <Container ref={scrollRef}>
      <Inner>
        {amountInvalid ? (
          <TooMany>
            <SvgIcon
              size="lg"
              SvgComponent={ListCheck}
              className="main"
              hue="white"
              shade="000"
            />
            <h3>
              {noItems
                ? "You need to add items to your list in order to compare"
                : "Select four items"}
            </h3>
            {noItems ? (
              <ButtonBlue className="auto-width" as={Link} to="/bed-finder/">
                Bed Finder
              </ButtonBlue>
            ) : (
              <Grey limit={filteredIds.length === LIMIT}>
                {products.map(({ name, wordpress_id }, i) => (
                  <Item
                    key={`${wordpress_id}-${i}`}
                    name={name}
                    checked={filteredIds.some((id) => id === wordpress_id)}
                    id={wordpress_id}
                    onClick={handleToggle}
                  />
                ))}
                <ButtonBlue
                  disabled={filteredIds.length === 0}
                  onClick={() => {
                    setAmountInvalid(false)
                    scrollRef.current.scrollIntoView()
                  }}
                >
                  Compare
                </ButtonBlue>
              </Grey>
            )}
          </TooMany>
        ) : (
          <div key={`force-rerender-${amountInvalid ? "2" : "3"}`}>
            <Responsive maxWidth={windowSizes.mobile}>
              <MobileWrapper
                items={filteredItems}
                getPrice={getMatPrice}
                handleRemoveEvent={handleRemove}
              />
            </Responsive>
            <Responsive minWidth={windowSizes.mobile}>
              {filteredItems && filteredItems.length > 0 && (
                <table>
                  <thead>
                    <tr key="row 1">
                      <th></th>
                      {filteredItems.map((pr, index) => {
                        return (
                          <th key={`${index}`}>
                            <img
                              src={pr.images[0].src}
                              width="200px"
                              height="200px"
                            />
                          </th>
                        )
                      })}
                    </tr>
                    <tr key="row 2">
                      <th></th>
                      {filteredItems.map((pr, index) => (
                        <th key={`${index}`} width="200px">
                          {pr.name}
                        </th>
                      ))}
                    </tr>
                    <tr key="row 3">
                      <th></th>
                      {filteredItems.map((pr, index) => (
                        <th key={`${index}`} className="price">
                          {getMatPrice(pr)}
                        </th>
                      ))}
                    </tr>
                    <tr key="row 4">
                      <th></th>
                      {filteredItems.map((pr, index) => (
                        <th key={`${index}`}>
                          <Button key={`${index}`} to={`/product/${pr.slug}`}>
                            VIEW PRODUCT
                          </Button>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <Row
                      title="Bedroom Type"
                      items={filteredItems}
                      render={(pr) =>
                        pr.acf.bedroom_type.map((roomType, index) => (
                          <p key={`${roomType}-${index}`}>{roomType}</p>
                        ))
                      }
                    />

                    <tr key="row 1">
                      {filteredItems.length > 0 && <td align="right">Sizes</td>}
                      {filteredItems &&
                        filteredItems.map((pr, index) => (
                          <td key={`${index}`} align="center">
                            {pr.attributes.find((at) => at.name == "Size") !=
                              null &&
                              pr.attributes
                                .filter((at) => at.name == "Size")[0]
                                .options.map((sizes, index) => (
                                  <p key={`${index}-${sizes}`}>{sizes}</p>
                                ))}
                          </td>
                        ))}
                    </tr>
                    {rows.map(([title, render], index) => (
                      <Row
                        key={`${title}-${index}`}
                        title={title}
                        render={render}
                        items={filteredItems}
                      />
                    ))}

                    <tr key="row 2">
                      <td align="right"></td>
                      {filteredItems.map((pr, index) => (
                        <td key={`${index}`} align="center">
                          <Remove onClick={() => handleRemove(pr.wordpress_id)}>
                            <div>REMOVE</div>
                            <SvgIcon
                              SvgComponent={Cross}
                              hue="midnight"
                              shade="040"
                            />
                          </Remove>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              )}
            </Responsive>
            {products.length > LIMIT && (
              <ButtonBlue
                className="pad"
                onClick={() => {
                  setAmountInvalid(true)
                  scrollRef.current.scrollIntoView()
                }}
              >
                Change selection
              </ButtonBlue>
            )}
          </div>
        )}

        {!noItems && (
          <ButtonBlue
            className="pad grey"
            onClick={() => {
              setOpen(true)
            }}
          >
            Share this
          </ButtonBlue>
        )}
      </Inner>
      <Modal
        closeTimeoutMS={400}
        isOpen={open}
        style={customStyles}
        onRequestClose={() => setOpen(false)}
        contentRef={(node) => {
          if (node) {
            var input = node.querySelector("input")
            copyRef.current = input
          }
        }}
      >
        <ModalWrap>
          <Input value={copyVal} />
          {data.copyImage && data.copyImage.childImageSharp.gatsbyImageData && (
            <GatsbyImage
              alt="copy-icon"
              image={
                data.copyImage && data.copyImage.childImageSharp.gatsbyImageData
              }
              title="Copy to clipboard"
              onClick={copyToClipboard}
            />
          )}
        </ModalWrap>
      </Modal>
    </Container>
  )
}

const LoadingBlock = styled.div`
  background: white;
  border-radius: 4px;
  min-width: 300px;
`

const ModalWrap = styled.div`
  display: flex;
  > div {
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #c3c3c3;
    padding: 3px;
    width: 40px;
    height: 40px;
    margin-left: 15px;
  }
`

const Input = styled.input`
  background: #e6e6e6;
  border-radius: 3px;
  border: 1px solid #c3c3c3;
  padding: 5px;
  width: 100%;
`

// Modal styles
const customStyles = {
  overlay: {
    background: "#162232de",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const Desktop = styled.div`
  display: block;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

const Button = styled(Link)`
  border: 0;
  background-color: #223247;
  border-radius: 4px;
  color: white;
  padding: 5px;
  text-transform: uppercase;
  width: 100%;
  display: block;
`

export const Remove = styled.button`
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ noBorder }) => (noBorder ? 0 : "2px solid #223247")};
  background: transparent;
  padding: 0;
  margin: 0;
  color: #223247;
  cursor: pointer;
  div {
    width: 100%;
    height: 100%;
    text-transform: uppercase;
  }
  span.icon {
    border-left: 2px solid #223247;
    padding: 5px;
  }
`

const Container = styled.div`
  background: #f6f6f6;
  padding: 60px 0;

  table {
    margin: 0 auto;
    border-spacing: 0;
    border-collapse: separate;
    font-size: 18px;
    color: #223247;

    span.icon {
      &.check {
        background: rgba(0, 200, 81, 0.37);
        border-radius: 200px;
      }
      &.cross {
        border-radius: 200px;
        background: rgba(186, 192, 197, 0.28);
      }
    }
    thead {
      .price {
        font-weight: bold;
      }
      tr {
        th:not(:first-child) {
          background: white;
          padding: 10px;
          border-left: 2px solid #cfd5db;
        }
        &:first-child {
          th:not(:first-child) {
            border-top: 2px solid #cfd5db;
          }

          th:nth-child(2) {
            border-top-left-radius: 4px;
          }
          th:last-child {
            border-top-right-radius: 4px;
          }
        }
        th:last-child {
          border-right: 2px solid #cfd5db;
        }
      }
    }
    tbody {
      background: white;
      td {
        padding: 10px;
      }
      tr:nth-child(even) {
        background: #dee3e8;
      }

      tr {
        td:first-child {
          text-align: left;
        }
        &:first-child {
          td {
            border-top: 2px solid #cfd5db;
            &:first-child {
              border-top-left-radius: 4px;
            }
          }
        }
        td {
          border-bottom: 2px solid #cfd5db;
          border-left: 2px solid #cfd5db;
          &:first-child {
            border-left: 2px solid #cfd5db;
          }
        }

        td:last-child {
          border-right: 2px solid #cfd5db;
        }

        &:last-child {
          td:first-child {
            background: #f6f6f6;
            border-left: none;
            border-right: none;
            border-bottom: none;
          }
          td:nth-child(2) {
            border-left: 2px solid #cfd5db;
            border-bottom-left-radius: 4px;
          }
          td:last-child {
            border-bottom-right-radius: 4px;
          }
        }
        &:nth-last-child(2) {
          td:first-child {
            border-bottom-left-radius: 4px;
          }
        }
      }
    }
  }
`

const TooMany = styled.div`
  background: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0px 1px 1px 2px #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 20px;
  > * {
    margin-bottom: 20px;
  }

  .icon.main {
    background: #162232;
    border-radius: 200px;
    padding: 10px;
  }
`

const Grey = styled.div`
  border-radius: 4px;
  background: #f8f8f8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-width: 300px;

  ${({ limit }) => limit && ".item:not(.checked){cursor:not-allowed;}"}

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    min-width: 200px;
  }
  > div {
    margin-bottom: 10px;
  }
`

const Item = ({ name, onClick, checked, id }) => {
  const color = checked
    ? { hue: "grass", shade: "100" }
    : { hue: "tusk", shade: "020" }
  return (
    <ItemWrap
      onClick={() => onClick(id)}
      className={checked ? "checked item" : "item"}
    >
      <CheckWrap className="check-wrap" checked={checked}>
        <SvgIcon SvgComponent={Check} {...color} />
      </CheckWrap>
      {name}
    </ItemWrap>
  )
}

const CheckWrap = styled.div`
  border-radius: 4px;
  border: 2px solid ${({ checked }) => (checked ? "#00C851" : "#dee3e8")};
  padding: 0px;
  margin-right: 10px;
  transition: 0.2s all ease-in-out;
`
const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const ButtonBlue = styled.button`
  border-radius: 4px;
  background: #162232;
  padding: 10px 15px;
  color: white;
  border: none;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.2s all ease-in-out;

  &.auto-width {
    width: auto;
  }

  &.pad {
    margin: 40px auto;
    margin-bottom: 0px;
    display: block;
    width: auto;
    font-size: 18px;
    padding: 10px 60px;
  }

  &.grey {
    background: #868686;
  }

  &:disabled {
    background: #eaeaea;
    cursor: not-allowed;
  }
`

export const CheckOrCross = ({ check }) =>
  check ? (
    <SvgIcon SvgComponent={Check} className="check" hue="grass" shade="100" />
  ) : (
    <SvgIcon SvgComponent={Cross} className="cross" hue="tusk" shade="060" />
  )

const Wrap = styled.div``

CompareProducts.propTypes = {
  search: PropTypes.object,
}

export default CompareLocation(CompareProducts)

const COMPARE_GRAPHQL = graphql`
  {
    copyImage: file(relativePath: { eq: "icons/copy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    ## ProductFilter: All beds and mattresses products
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
        categories: { elemMatch: { slug: { in: ["beds", "mattresses"] } } }
      }
    ) {
      nodes {
        wordpress_id
        name
        slug
        sku
        price
        attributes {
          name
          options
        }
        bundled_items {
          wordpress_id
        }
        product_variations {
          price
        }
        images {
          src
        }
        acf {
          id
          bedroom_type
          fabric_type
          guarantee
          high_resilience_foam
          latex_foam
          limited_movement_transfer
          mattress_in_box
          mattress_type
          memory_foam
          sizes
          turn__no_turn_mattress
          warrantee
          weight_capacity
          zero_movement_transfer
          comfort_trial_available
          comfort_level
        }
      }
    }
  }
`

const Row = ({ title, items, render }) => {
  return (
    <tr key={`${title}-parent`}>
      <td align="right">{title}</td>
      {items.map((item, idx) => {
        return (
          <td key={`${title}: ${idx}`} align="center">
            {render(item)}
          </td>
        )
      })}
    </tr>
  )
}

const showCheck = (prop) => ({ acf }) => (
  <CheckOrCross check={acf[prop] == "Yes"} />
)

const rows = [
  // [Title, renderFn]
  // ["Comfort Level", ({ acf }) => acf.comfort_level.name],
  [
    "Weight Capacity",
    ({ acf }) =>
      acf.weight_capacity ? acf.weight_capacity + " kg" : "No Limit",
  ],
  ["Mattress Type", ({ acf }) => (acf.mattress_type ? acf.mattress_type : "")],
  ["Guarantee", ({ acf }) => (acf.guarantee ? acf.guarantee + " year" : "")],
  ["Warranty", ({ acf }) => (acf.warrantee ? acf.warrantee + " year" : "")],
  ["High Resilience Foam", showCheck("high_resilience_foam")],
  ["Latex Foam", showCheck("latex_foam")],
  ["Memory Foam", showCheck("memory_foam")],
  ["Fabric Type", ({ acf }) => (acf.fabric_type ? acf.fabric_type : "")],
  ["Zero Movement Transfer", showCheck("zero_movement_transfer")],
  ["Limited Movement Transfer", showCheck("limited_movement_transfer")],
  [
    "No Turn Mattress",
    ({ acf }) => (
      <CheckOrCross check={acf.turn__no_turn_mattress == "No Turn"} />
    ),
  ],
  ["Mattress in Box", showCheck("mattress_in_box")],
  ["100 Day Comfort Trial Available", showCheck("comfort_trial_available")],
]
