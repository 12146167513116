import React from "react"

const IconInfo = ({ fill }) => (
  <svg width="6" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.55 11.6h.54V7.65H.55A.55.55 0 010 7.1V5.8c0-.3.24-.55.55-.55H3.6c.3 0 .55.24.55.55v5.8h.54c.3 0 .55.24.55.55v1.3c0 .3-.24.55-.55.55H.55a.55.55 0 01-.55-.55v-1.3c0-.3.24-.55.55-.55zM2.62 0a1.97 1.97 0 110 3.94 1.97 1.97 0 010-3.94z"
      fill={fill}
    />
  </svg>
)

export default IconInfo
