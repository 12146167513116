import React from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"
import { path } from "../../../../lib/util"
import SvgIcon from "../../../atoms/icons/svg-icon"
import Phone from "../../../atoms/icons/files/phone.jsx"
import Mobile from "../../../atoms/icons/files/mobile.jsx"
import ArrowRight from "../../../atoms/icons/files/arrow-right"

const AreaLink = ({ node }) => {
  const phone_1 = path(["branch_details", "telephoneNumbers", "phone1"], node)
  const phone_2 = path(["branch_details", "telephoneNumbers", "phone2"], node)

  return (
    <StyledLink to={`/contact-us/${node.slug}/`}>
      <h4>
        {node.title} <ArrowRight fill="#223247" />
      </h4>
      {phone_1 && (
        <IconText>
          <SvgIcon size="sm" hue="midnight" shade="060" SvgComponent={Phone} />
          {phone_1}
        </IconText>
      )}
      {phone_2 && (
        <IconText>
          <SvgIcon size="sm" hue="midnight" shade="060" SvgComponent={Mobile} />
          {phone_2}
        </IconText>
      )}
    </StyledLink>
  )
}

export default AreaLink

const StyledLink = styled(Link)`
  border-radius: 4px;
  background: #d9e4f3;
  padding: 15px;
  color: #223247;
  h4 {
    font-weight: 600;
    margin-bottom: 7px;
  }
  svg {
    transition: 0.2s all ease-in-out;
  }
  transition: 0.2s all ease-in-out;
  &:hover {
    background: #fff;
    h4 > svg {
      transform: translateX(4px);
      #icon {
        fill: ${({ theme }) => theme.colors.ruby["060"]};
      }
    }
  }
`

const IconText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  svg {
    margin-right: 8px;
  }
`
