import React from "react"

const IconLogin = ({ fill = "#333" }) => (
  <svg width="14" height="11" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.03 1.03l4.5 4.49c.13.13.13.33 0 .46l-4.5 4.49a.33.33 0 01-.46 0l-.2-.2a.33.33 0 010-.46l3.61-3.6H.33A.33.33 0 010 5.9V5.6c0-.18.15-.32.33-.32h7.65l-3.6-3.6a.33.33 0 010-.46l.19-.2a.33.33 0 01.46 0zM14 9.7c0 .72-.59 1.31-1.31 1.31H9.08a.33.33 0 01-.33-.33v-.22c0-.18.15-.32.33-.32h3.6c.25 0 .44-.2.44-.44V1.8c0-.24-.2-.43-.43-.43H9.08a.33.33 0 01-.33-.33V.83c0-.18.15-.33.33-.33h3.6c.73 0 1.32.59 1.32 1.31V9.7z"
      fill={fill}
    />
  </svg>
)

export default IconLogin
