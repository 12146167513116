import React from "react"

const IconTag = ({ fill }) => (
  <svg width="18" height="15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.38 8.78l-5.58 5.59c-.51.5-1.35.5-1.86 0l-.05-.05.62-.62.05.05c.17.17.45.17.62 0l5.58-5.58a.44.44 0 000-.62l-5.8-5.8a.5.5 0 00-.3-.12h-.54L9.25.75h1.4c.3 0 .72.17.94.38l5.79 5.8c.51.51.51 1.34 0 1.85zM1.58 1.63c-.25 0-.44.2-.44.43v5.58c0 .12.04.23.13.31l5.79 5.8c.17.17.45.17.62 0l5.58-5.58a.44.44 0 000-.62l-5.8-5.8a.5.5 0 00-.3-.12H1.58zM7.16.75c.3 0 .71.17.93.38l5.79 5.8c.51.51.51 1.34 0 1.85L8.3 14.37c-.51.5-1.35.5-1.86 0l-5.8-5.8a1.5 1.5 0 01-.38-.93V2.06c0-.72.6-1.31 1.32-1.31h5.58zM4.2 4.15a.55.55 0 100 1.09.55.55 0 000-1.1zm0-.77A1.31 1.31 0 114.2 6a1.31 1.31 0 010-2.62z"
      fill={fill}
    />
  </svg>
)

export default IconTag
