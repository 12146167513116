import React, { useContext, useState, useEffect, useRef } from "react"
import BranchContext from "../../context/BranchContext"
import CartContext from "../../context/CartContext"
import styled from "styled-components"
import BlurLoader from "../../zzz/organisms/blurLoader"
import BranchSelectButton from "../BranchSelect/BranchSelectButton"
import Drawer from "../../zzz/molecules/drawer"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ArrowLeft from "../../zzz/atoms/icons/files/arrow-left.jsx"
import ShoppingCart from "../../zzz/atoms/icons/files/shopping-cart.jsx"
import Delivery from "../../zzz/atoms/icons/files/delivery.jsx"
import Tag from "../../zzz/atoms/icons/files/tag.jsx"
import Lock from "../../zzz/atoms/icons/files/lock.jsx"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import RelatedProducts from "./RelatedProducts"
import CartDrawerItems from "./CartDrawerItems"
import ComfortTrialItems from "./ComfortTrialItems"
import Coupon from "./Coupon"
// import { segmentViewCart } from "../../services/segment"
import { sendRetentionScience } from "../../services/RetentionScience"
import { isInStoreFrontend } from "../../services/auth"
import { gtmViewCart } from "../../services/googleTagManagerDataLayerTracking"

import {
  getShippingClassInCart,
  formatCurrencyInt,
  slugify,
  path,
} from "../../lib/util"
import { titleCase, replaceAll } from "../../lib/stringHandling"
import DonateYourBed from "../Checkout/DonateYourBed"

const CartDrawer = () => {
  const { selectedBranch } = useContext(BranchContext)
  const {
    cartDrawerOpen,
    setCartDrawerOpen,
    cartContents,
    cartMeta,
    cartCount,
    getCart,
    cartReady,
  } = useContext(CartContext)

  const [message, setMessage] = useState("")
  const [scrollPosition, setScrollPosition] = useState(0)
  const isInStoreFrontendState = isInStoreFrontend()

  const continueButtonRef = useRef()

  useEffect(() => {
    if (window?.location?.search?.includes?.("cart")) {
      const questionMarkRemoved = window?.location?.search?.slice?.(1)
      const params = questionMarkRemoved?.split?.("&")
      if (params?.includes("cart")) {
        setCartDrawerOpen(true)
      }
    }
  }, [])

  // Every time the cart drawer opens, send the analytics event
  useEffect(() => {
    if (cartDrawerOpen) {
      gtmViewCart(cartContents)
      // segmentViewCart(cartContents)
      sendRetentionScience({ cartContents, viewCart: true })
    }
  }, [cartDrawerOpen])

  // After a new Coupon was successfully added
  const onCouponAdded = () => {
    setMessage("")
    getCart().catch((error) => {
      if (error && error.message) {
        setMessage(error.message)
      } else {
        setMessage("Failed to complete action")
      }
    })
  }

  // Helper access variables
  const cartTotalTax = path(["cart_contents_tax"], cartMeta)
  const cartTotalContents = path(["subtotal"], cartMeta)
  const cartTotalDiscounts =
    path(["discount_total"], cartMeta) + path(["discount_tax"], cartMeta)
  const cartTotalDiscounted =
    cartTotalContents - cartTotalDiscounts > 0
      ? cartTotalContents - cartTotalDiscounts
      : 0
  const cartHasItems = cartContents && cartContents.length > 0
  const cartHasDiscount = cartMeta && cartMeta.discount_total > 0

  useEffect(() => {
    if (typeof window != "undefined") {
      const bodyNode = document.querySelector("body")
      if (cartDrawerOpen) {
        // if the cart drawer is open set the background (body element of the DOM)
        // to position fixed and the top to the current scroll postion to avoid the
        // page bouncing around
        const newScrollPosition = window.pageYOffset
        bodyNode.style.top = `-${scrollPosition}px`
        bodyNode.style.position = "fixed"
        bodyNode.style.overflow = "hidden"
        bodyNode.style.width = "100%"
        setScrollPosition(newScrollPosition)

        // set continue button to be in focus
        continueButtonRef?.current?.focus?.()
      } else {
        // if the cart drawer is closed remove properties from the background
        // (body element of the DOM) and scroll the page to the correct offset
        bodyNode.style.removeProperty("position")
        bodyNode.style.removeProperty("top")
        bodyNode.style.removeProperty("overflow")
        bodyNode.style.removeProperty("width")
        window.scrollTo(0, scrollPosition)
      }
    }
  }, [cartDrawerOpen])

  // Prepare cart items to show
  const cartContentsToShow =
    cartContents
      ?.map((item) => {
        if (item.bundled_by) {
          return null
        } else {
          let productName = item.product_name
          let productSku = item.tracking_sku ? item.tracking_sku : item.sku
          // If product_name == product_title, it means the size was not appended
          // in the backend so attempt to do so now
          if (productName === item.product_title) {
            productName = `${productName}${
              item.size
                ? ` - ${titleCase(replaceAll(item.size, "-", " "))}`
                : ""
            }`
          }
          const shippingClassForLocation = getShippingClassInCart(
            cartContents,
            item,
            selectedBranch
          )

          const comfortTrialApplicable = item?.comfort_trial_available == "Yes"
          const productSize = slugify(item?.size)
          const comfortTrialQualified = comfortTrialApplicable
            ? cartContents
                .filter((it) =>
                  it.categories?.some(
                    (cat) => cat.slug == "mattress-protectors"
                  )
                )
                .some((mattProtector) =>
                  slugify(mattProtector?.size)?.includes(productSize)
                )
            : false

          return {
            ...item,
            productName,
            productSku,
            shippingClassForLocation,
            productSize,
            comfortTrialApplicable,
            comfortTrialQualified,
          }
        }
      })
      ?.filter((i) => !!i) || []

  // Beds/Mattresses where 100 day comfort trial is applicable
  const comfortTrialItems = cartContentsToShow.filter(
    (it) => it?.comfortTrialApplicable
  )

  return (
    <Drawer
      scroll
      open={cartDrawerOpen}
      closeFn={() => setCartDrawerOpen(false)}
    >
      <BlockWrap>
        <CartHeader>
          <SvgIcon
            onClick={() => setCartDrawerOpen(false)}
            SvgComponent={ArrowLeft}
            size="lg"
            className="point"
          />
          <div>
            <SvgIcon
              SvgComponent={ShoppingCart}
              size="lg"
              hue="ocean"
              shade="060"
            />
            <h4>Your Cart</h4>
            <Circle>{cartCount}</Circle>
          </div>
        </CartHeader>
        <CartHeaderSpacer />
        {message && <ErrorMessage>{message}</ErrorMessage>}
        <BlurLoader loading={!cartReady} message="Updating">
          <BranchSelectContainer>
            <StyledBranchSelectButton shade="080" />
          </BranchSelectContainer>
          {cartHasItems && cartMeta ? (
            <CartDrawerItems
              isInStoreFrontendState={isInStoreFrontendState}
              setMessage={setMessage}
              cartContentsToShow={cartContentsToShow}
            />
          ) : (
            <NoItems>Add items to your cart and they will appear here</NoItems>
          )}
        </BlurLoader>
        <LineItem className={!cartReady && "blur"}>
          <SvgIcon SvgComponent={Delivery} size="md" hue="tusk" shade="080" />
          <span className="label">Shipping</span>
          <span className="small-italics">Free within 100km of city hubs!</span>
        </LineItem>
        {comfortTrialItems?.length > 0 && (
          <BlurLoader loading={!cartReady}>
            <ComfortTrialItems comfortTrialItems={comfortTrialItems} />
          </BlurLoader>
        )}
        {cartHasDiscount && (
          <LineItem className={!cartReady && "blur"}>
            <SvgIcon SvgComponent={Tag} size="md" hue="tusk" shade="080" />
            <span className="label">Discount</span>
            <span className="bold">{`- R ${formatCurrencyInt.format(
              cartTotalDiscounts
            )}`}</span>
          </LineItem>
        )}
        <LineItem className={!cartReady && "blur"}>
          <div className="total">
            <span>
              Estimated Total:
              <span className="sub">
                {`Incl. VAT (R ${formatCurrencyInt.format(cartTotalTax)})`}
              </span>
            </span>
            <span>
              <span
                className={
                  cartHasDiscount ? "total-value striked" : "total-value"
                }
              >
                R {formatCurrencyInt.format(cartTotalContents)}
              </span>
              {cartHasDiscount && (
                <span className="total-value">
                  R {formatCurrencyInt.format(cartTotalDiscounted)}
                </span>
              )}
            </span>
          </div>
        </LineItem>
        <LineItem className={!cartReady && "blur"}>
          <Coupon onCouponAdded={onCouponAdded} />
        </LineItem>
        <LineItem className={!cartReady && "blur"}>
          <DonateYourBed centered={true} />
        </LineItem>
        <Inner>
          <Button
            to="/checkout/"
            onClick={() => setCartDrawerOpen(false)}
            disabled={!cartHasItems || !cartReady}
            innerRef={continueButtonRef}
          >
            <SvgIcon SvgComponent={Lock} hue="white" shade="000" />
            Checkout
          </Button>
        </Inner>
        <RelatedProducts setMessage={setMessage} />
        <Info>
          <SvgIcon SvgComponent={Lock} size="md" hue="tusk" shade="080" />{" "}
          Secure Checkout
        </Info>
      </BlockWrap>
    </Drawer>
  )
}

export default CartDrawer

const StyledBranchSelectButton = styled(BranchSelectButton)`
  color: black;
  &:hover {
    color: black;
    transform: translateY(-1px);
  }
`

const BranchSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  padding: 20px 0;
`

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9ba5ae;
  padding: 10px 10px 20px 10px;
  border-top: 2px dashed #54606e17;
  span.icon {
    margin-right: 3px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding-bottom: 50px;
  }
`

const NoItems = styled.div`
  padding: 30px;
  margin: 30px;
  border-radius: 3px;
  border: 1px solid #b1b1b1;
  background: #f9f9f9;
  text-align: center;
`

const Button = styled(Link)`
  background: ${(props) => (props.disabled ? "#b1b1b1" : "#fd3237")};
  ${(props) => props.disabled && "pointer-events: none;"}
  border-radius: 3px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 10px;
  display: block;
  margin: 20px 0;
  font-size: 20px;
  .icon {
    margin-right: 15px;
  }
`

const Inner = styled.div`
  padding: 0 20px;
`

export const LineItem = styled.div`
  padding: 10px 30px;
  border-bottom: 2px dashed #54606e17;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  transition: 0.2s all ease-in-out;

  &.blur {
    filter: blur(3px);
  }

  &.highlight {
    background-color: #b6c9e3;
    border: none;
    .bold {
      font-size: 21px;
    }
  }

  &.small {
    padding: 15px 15px;
    span {
      &:nth-child(2) {
        margin-left: 13px;
      }
      font-size: 16px;
      &.bold {
        font-weight: bold;
      }
    }
    .total {
      .sub {
        font-size: 14px;
      }
    }
  }
  &.promo {
    background: #09b6d270;
  }
  .label {
    flex: 1 1 auto;
    margin-left: 13px;
  }
  .price {
    white-space: nowrap;
    min-width: 80px;
  }
  .total-value {
    text-align: right;
    font-size: 20px;
  }
  .striked {
    text-decoration: line-through;
    font-size: 14px;
  }
  .promo {
    font-size: 14px;
    text-align: right;
    color: #3b5371;
    font-weight: 300;
  }
  .small-italics {
    margin-left: 20px;
    font-style: italic;
    font-size: 14px;
    font-weight: 300;
  }
  .bold {
    text-align: right;
    font-size: 16px;
    font-weight: 600;
  }

  .total {
    white-space: nowrap;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    .sub {
      font-size: 16px;
      color: #3b5371;
      font-weight: 300;
    }

    > span {
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      justify-content: center;
      font-size: 18px;
      &.italic {
        font-style: italic;
      }
    }
  }
`

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
`

const CartHeaderSpacer = styled.div`
  height: 105px;
  z-index: 0;
`

const CartHeader = styled.div`
  z-index: 99;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: white;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.41);
  h2 {
    font-weight: bold;
  }
  h4 {
    font-weight: bold;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > * {
      margin-right: 15px;
    }
  }
  .point {
    cursor: pointer;
    position: absolute;
    left: 20px;
    transition: 0.2s all ease-in-out;
    &:hover {
      transform: translateX(-4px);
    }
  }
`

const Circle = styled.div`
  background: #0998d2;
  color: white;
  padding: 10px;
  border-radius: 200px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BlockWrap = styled.div`
  background: white;
  /* padding: 30px; */
  transition: 0.1s all ease-in-out;
`
