import React from "react"

const Search = ({ fill }) => (
  <svg
    data-name="icon/search"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M18.05 19a.33.33 0 01-.23-.1l-3.32-3.31a.34.34 0 01-.1-.24V15a5.69 5.69 0 11.59-.58h.36a.33.33 0 01.23.1l3.32 3.31a.33.33 0 010 .47l-.62.61a.33.33 0 01-.23.1zM10.7 6.31a4.38 4.38 0 104.37 4.38A4.38 4.38 0 0010.7 6.3z"
      fill={fill}
    />
  </svg>
)

export default Search
