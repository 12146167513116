import React from "react"

const UserCircle = ({ fill }) => (
  <svg width="14" height="15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 .969a6.78 6.78 0 016.781 6.781A6.78 6.78 0 017 14.531 6.78 6.78 0 01.219 7.75 6.78 6.78 0 017 .969zm3.5 11.528v-.372c0-.965-.785-1.75-1.75-1.75-.306 0-.75.312-1.75.312-.998 0-1.447-.312-1.75-.312-.965 0-1.75.785-1.75 1.75v.372a5.875 5.875 0 003.5 1.16c1.31 0 2.518-.436 3.5-1.16zm.837-.752a5.874 5.874 0 001.57-3.995A5.913 5.913 0 007 1.844 5.913 5.913 0 001.094 7.75c0 1.542.599 2.942 1.57 3.995A2.617 2.617 0 015.25 9.5c.563 0 .831.312 1.75.312S8.19 9.5 8.75 9.5c1.32 0 2.4.976 2.587 2.245zM7 4.03a2.406 2.406 0 110 4.813A2.406 2.406 0 017 4.03zM7 7.97a1.532 1.532 0 000-3.063A1.532 1.532 0 007 7.97z"
      fill={fill}
    />
  </svg>
)

export default UserCircle
