import React, { useContext, memo, useEffect } from "react"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Grid from "../../layout/grid"

const announcement = () => {
  const data = useStaticQuery(graphql`
    {
      megaphoneImage: file(relativePath: { eq: "megaphone-icon.png" }) {
        childImageSharp {
          gatsbyImageData(height: 20, layout: FIXED)
        }
      }
      localWpGraphQlTmwGeneralSettings {
        announcement {
          showAnnouncement
          announcementText
        }
      }
    }
  `)

  const showAnnouncement =
    data.localWpGraphQlTmwGeneralSettings.announcement.showAnnouncement
  const announcementText =
    data.localWpGraphQlTmwGeneralSettings.announcement.announcementText
  const megaphoneImage = data.megaphoneImage.childImageSharp.gatsbyImageData

  if (showAnnouncement === "yes") {
    return (
      <AnnouncementWrapper>
        <Grid cols="1" narrowGutter="15px">
          <div className="announcement_mobile centered small">
            <GatsbyImage
              alt="megaphone-icon"
              height="30px"
              loading="eager"
              placeholder="none"
              image={megaphoneImage}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: announcementText,
              }}
            ></div>
          </div>
        </Grid>
      </AnnouncementWrapper>
    )
  } else {
    return ""
  }
}

export default memo(announcement)

const AnnouncementWrapper = styled.div`
  background-color: #2ecc71;
  color: #fff;
  padding: 5px 0;
  .grid {
    grid-gap: 20px;
  }
  .centered {
    margin: 0 auto;
    div {
      display: inline-block;
    }
  }
  .gatsby-image-wrapper {
    margin-right: 5px;
  }
  .announcement_mobile {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .grid {
      grid-template-columns: 1fr auto auto;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding-top: 70px;
    margin-bottom: -70px;
    .gatsby-image-wrapper {
      width: 20px;
      min-width: 20px;
      img {
        width: 18px;
        height: 20px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`
