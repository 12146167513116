import React from "react"

const SocialYouTube = ({ fill }) => (
  <svg width="18" height="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.72 1.87c.14.57.25 1.42.31 2.57L17.06 6l-.03 1.56a14.29 14.29 0 01-.31 2.6 2.18 2.18 0 01-1.53 1.5c-.54.14-1.82.25-3.82.3L8.53 12l-2.84-.03c-2-.06-3.27-.17-3.82-.31-.37-.1-.7-.3-.96-.57a2.18 2.18 0 01-.57-.93C.2 9.59.1 8.73.04 7.56L0 6l.03-1.56c.06-1.15.17-2 .31-2.57A2.17 2.17 0 011.87.34C2.42.2 3.7.1 5.7.04L8.53 0l2.84.03c2 .06 3.28.17 3.82.31a2.17 2.17 0 011.53 1.53zm-9.94 6.7L11.25 6 6.78 3.47v5.1z"
      fill={fill}
    />
  </svg>
)

export default SocialYouTube
