import React from "react"

const IconMobile = ({ fill }) => (
  <svg width="10" height="15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.87 12.13a.87.87 0 11-1.74 0 .87.87 0 011.74 0zm.88-8.75h-3.5v6.56h3.5V3.38zm.55-.88c.18 0 .32.15.32.33v7.65c0 .18-.14.33-.32.33H2.7a.33.33 0 01-.33-.33V2.83c0-.18.15-.33.33-.33h4.6zm2.07-.44v11.38c0 .72-.58 1.31-1.3 1.31H1.93c-.73 0-1.31-.59-1.31-1.31V2.06c0-.72.58-1.31 1.3-1.31h6.13c.73 0 1.31.59 1.31 1.31zm-.87 0c0-.24-.2-.43-.44-.43H1.94c-.24 0-.44.2-.44.43v11.38c0 .24.2.43.44.43h6.12c.24 0 .44-.2.44-.43V2.06z"
      fill={fill}
    />
  </svg>
)

export default IconMobile
