import React, { useContext } from "react"
import styled from "styled-components"
import Grid from "../../layout/grid"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import LinkWrapper from "../../../utils/linkWrapper"

const PaymentMethodStrip = () => {
  const {
    ccImage: {
      childImageSharp: { gatsbyImageData: credit_card },
    },
    feverImage: {
      childImageSharp: { gatsbyImageData: fevertree },
    },
    payflexImage: {
      childImageSharp: { gatsbyImageData: payflex },
    },
    zeropayImage: {
      childImageSharp: { gatsbyImageData: zeropay },
    },
    payjustnowImage: {
      childImageSharp: { gatsbyImageData: payjustnow },
    },
    mobicredImage: {
      childImageSharp: { gatsbyImageData: mobicred },
    },
    masterpassImage: {
      childImageSharp: { gatsbyImageData: masterpass },
    },
    payfastEftImage: {
      childImageSharp: { gatsbyImageData: instantEft },
    },
    ozowImage: {
      childImageSharp: { gatsbyImageData: ozow },
    },
    floatImage: {
      childImageSharp: { gatsbyImageData: float },
    },
  } = useStaticQuery(CHECKOUT_QUERY)

  let PaymentMethodItems = [
    {
      img: credit_card,
      alt: "Credit Card",
      id: "creditcard_id",
    },
    {
      img: instantEft,
      alt: "Instant EFT",
      id: "instanteft_id",
    },
    {
      img: ozow,
      alt: "Ozow",
      id: "ozow_id",
    },
    {
      img: masterpass,
      alt: "MasterPass",
      id: "masterpass_id",
    },
    {
      img: zeropay,
      alt: "ZeroPay",
      id: "zeropay_id",
    },
    {
      img: payjustnow,
      alt: "PayJustNow",
      id: "payjustnow_id",
    },
    {
      img: payflex,
      alt: "PayFlex",
      id: "payflex_id",
    },
    {
      img: float,
      alt: "Pay with Float",
      id: "float_id",
    },
    // {
    //   img: fevertree,
    //   alt: "The Mattress Warehouse Accounts by FeverTree",
    //   id: "fevertree_id",
    // },
    // {
    //   img: mobicred,
    //   alt: "MobiCred",
    //   id: "mobicred_id"
    // }
  ]

  // console.log(PaymentMethodItems)

  return (
    <Wrapper>
      <Grid cols="4" gutter="15px" align="flex-center">
        {PaymentMethodItems.map((value, key) => {
          return (
            <Item key={"footerPaymentMethod_" + key} className={"Item-" + key}>
              <LinkWrapper to={"/payment-providers/#" + value.id}>
                <GatsbyImage
                  image={value.img}
                  style={{ maxHeight: "50px" }}
                  imgStyle={{ objectFit: "contain" }}
                  placeholder="none"
                  objectFit="contain"
                  objectPosition="50% 50%"
                  alt={value.alt}
                />
              </LinkWrapper>
            </Item>
          )
        })}
      </Grid>
    </Wrapper>
  )
}

export default PaymentMethodStrip

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: #071322;
  padding: 15px 0;
  @media (max-width: 768px) {
    display: none;
  }
`

const Item = styled.div`
  text-align: center;
  width: 100%;
  img {
  }
`

const CHECKOUT_QUERY = graphql`
  {
    ccImage: file(relativePath: { eq: "credit_card_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    feverImage: file(relativePath: { eq: "fevertree_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    payflexImage: file(relativePath: { eq: "payflex_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    zeropayImage: file(relativePath: { eq: "zeropay_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    payjustnowImage: file(relativePath: { eq: "payjustnow_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    payfastEftImage: file(relativePath: { eq: "payfast_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobicredImage: file(relativePath: { eq: "mobicred.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    masterpassImage: file(relativePath: { eq: "masterpass_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ozowImage: file(relativePath: { eq: "ozow_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    floatImage: file(relativePath: { eq: "float_white.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
