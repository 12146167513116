import React from "react"

const SocialPinterest = ({ fill }) => (
  <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.37 0c.98.02 1.9.24 2.77.67a5.14 5.14 0 012.05 1.77c.54.81.81 1.72.81 2.73s-.17 1.97-.5 2.86a5.4 5.4 0 01-1.6 2.31c-.76.63-1.67.94-2.71.94-.42 0-.84-.1-1.25-.3a1.9 1.9 0 01-.9-.82l-.26 1a20.18 20.18 0 01-.66 2.25c-.25.56-.7 1.28-1.34 2.15l-.12.06-.1-.09-.03-.47a13.5 13.5 0 01-.1-1.31c0-.63.11-1.44.32-2.44.12-.58.33-1.45.62-2.6l.38-1.5c-.2-.39-.31-.9-.31-1.55 0-.6.15-1.1.47-1.5.27-.34.6-.55 1-.63.4-.08.74 0 1.03.22.29.23.43.6.43 1.1 0 .26-.04.57-.12.9-.04.19-.14.48-.28.87-.15.4-.24.7-.28.88-.09.33-.13.62-.13.87 0 .4.14.73.42.99.29.26.62.39 1.02.39.67 0 1.23-.3 1.69-.88.4-.47.68-1.12.87-1.93a8.1 8.1 0 00.25-1.97c0-1.08-.34-1.93-1.01-2.53-.68-.6-1.57-.9-2.68-.9-.8 0-1.55.18-2.23.55a4.06 4.06 0 00-2.14 3.75c0 .36.06.7.19 1.03.06.2.17.42.34.7.13.2.19.34.19.4 0 .17-.04.37-.13.62-.1.32-.22.47-.34.47-.27 0-.56-.14-.88-.42A3.25 3.25 0 01.34 7.5 4.93 4.93 0 010 5.62c0-1.12.31-2.12.94-3A6.02 6.02 0 013.28.7C4.26.23 5.3 0 6.38 0z"
      fill={fill}
    />
  </svg>
)

export default SocialPinterest
