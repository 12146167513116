import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import searchIcon from "../../images/icons/search.svg"

const SearchInput = ({
  className = "",
  searchPlaceholderText,
  query,
  performSearch,
}) => {
  return (
    <ElasticSearchInputStyling
      className={className}
      type="text"
      placeholder={searchPlaceholderText}
      defaultValue={query ? query : undefined}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          if (
            typeof window != "undefined" &&
            window.location.pathname.includes("search")
          ) {
            window.location = `${window.location.origin}${window.location.pathname}?query=${e.target.value}`
          } else {
            navigate(`/search/?query=${e.target.value}`)
          }
        }
      }}
      onChange={performSearch}
    />
  )
}

export default SearchInput

export const filterSearchResults = (response) => {
  if (response && response.rawResults && response.rawResults.length) {
    const results = response.rawResults.sort(
      (a, b) => parseFloat(b["_meta"].score) - parseFloat(a["_meta"].score)
    )
    const filteredProducts = []
    const filteredPosts = []
    const filteredPages = []
    const filteredBrands = []
    for (let i = 0; i < results.length; ++i) {
      switch (results[i].result_type.raw) {
        case "product": {
          filteredProducts.push(results[i])
          break
        }
        case "post": {
          filteredPosts.push(results[i])
          break
        }
        case "page": {
          filteredPages.push(results[i])
          break
        }
        case "brand": {
          filteredBrands.push(results[i])
          break
        }
        default: {
          break
        }
      }
    }

    return {
      products: filteredProducts,
      brands: filteredBrands,
      pages: filteredPages,
      posts: filteredPosts,
    }
  }
  // hide all results when string goes empty
  return {
    products: [],
    brands: [],
    pages: [],
    posts: [],
  }
}

// ==========
// 	 STYLES
// ==========
const ElasticSearchInputStyling = styled.input`
  padding: 15px 45px 15px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #3e4b5c;
  width: 100%;
  border-radius: 3px;
  background-color: #223247;
  color: white;
  font-size: 1rem;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: 97% center;
  :focus {
    outline: -webkit-focus-ring-color auto 1;
  }
  -webkit-text-fill-color: white;
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`
