import React from "react"

const ShoppingCart = ({ fill }) => (
  <svg width="16" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.093 1.75c.417 0 .728.383.643.79l-1.185 5.688a.656.656 0 01-.642.522H4.847l.329 1.75h8.095c.312 0 .566.216.636.496a1.75 1.75 0 11-2.97 1.254v-.002c0-.258.106-.65.235-.873h-3.97c.13.224.235.615.235.873v.002a1.75 1.75 0 11-3.044-1.177L2.48.875H.328A.328.328 0 010 .547V.328C0 .147.147 0 .328 0h2.334c.316 0 .587.225.645.535l.228 1.215h11.558zm-8.53 10.5a.876.876 0 00-1.75 0 .876.876 0 001.75 0zm6.124.875a.876.876 0 000-1.75.876.876 0 000 1.75zm1.044-5.25l1.094-5.25H3.699l.984 5.25h9.048z"
      fill={fill}
    />
  </svg>
)

export default ShoppingCart
