import React, { useContext, useState, useEffect } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import styled from "styled-components"
import Drawer from "../../zzz/molecules/drawer"
import {
  getCompareList,
  removeFromCompare,
  getCompareLink,
} from "./CompareProducts"
import EllipsisLoader from "../../zzz/organisms/ellipsisLoader"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ListCheck from "../../zzz/atoms/icons/files/list-check.jsx"
import Lock from "../../zzz/atoms/icons/files/lock.jsx"
import Cross from "../../zzz/atoms/icons/files/cross.jsx"
import ArrowLeft from "../../zzz/atoms/icons/files/arrow-left.jsx"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"
import CartContext from "../../context/CartContext"
import { GatsbyImage } from "gatsby-plugin-image"

const CompareDrawer = () => {
  const { compareDrawer, setCompareDrawerOpen } = useContext(CartContext)
  const graphqlData = useStaticQuery(COMPARE_PRODUCTS)
  const compareProducts = graphqlData.allWcProducts.nodes

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (compareDrawer) {
      updateItems()
    }
  }, [compareDrawer])

  const updateItems = () => {
    setLoading(true)
    const list = getCompareList()
    if (list) {
      setItems(inLookup(compareProducts, createLookup(list)))
    } else {
      setItems([])
    }

    setLoading(false)
  }

  const handleRemove = (id) => {
    removeFromCompare(id)
    updateItems()
  }

  let currentComparisonLink
  if (compareDrawer) {
    currentComparisonLink = getCompareLink()
  }

  return (
    <Drawer
      scroll
      open={compareDrawer}
      closeFn={() => setCompareDrawerOpen(false)}
    >
      <Wrap>
        <Heading>
          <IconText>
            <SvgIcon
              size="lg"
              hue="ocean"
              shade="060"
              SvgComponent={ListCheck}
            />
            Compare Products
          </IconText>
          <Sub>The below products have been selected to compare.</Sub>
          <SvgIcon
            SvgComponent={Cross}
            className="close"
            size="lg"
            onClick={() => setCompareDrawerOpen(false)}
          />
        </Heading>
        <Items>
          {loading && <EllipsisLoader />}
          {items.length === 0 && "No items to compare"}
          {items.map((item, i) => (
            <Card i={i} key={item.wordpress_id}>
              <Remove onClick={() => handleRemove(item.wordpress_id)}>
                <SvgIcon SvgComponent={Cross} hue="tusk" shade="080" />
              </Remove>
              {item.images &&
                item.images[0] &&
                item.images[0].localFile &&
                item.images[0].localFile.childImageSharp.gatsbyImageData && (
                  <GatsbyImage
                    image={
                      item.images[0].localFile.childImageSharp.gatsbyImageData
                    }
                    style={{ height: "200px", width: "200px" }}
                  />
                )}
              {item.name}
            </Card>
          ))}
        </Items>
        <Nav>
          <Button
            className="secondary left"
            onClick={() => setCompareDrawerOpen(false)}
          >
            <SvgIcon SvgComponent={ArrowLeft} size="lg" />
            <div>Back</div>
          </Button>
          <Button
            className="right"
            to={currentComparisonLink}
            onClick={() => {
              setCompareDrawerOpen(false)
              navigate(currentComparisonLink)
            }}
          >
            <div>Compare Now</div>
            <SvgIcon
              SvgComponent={ArrowRight}
              size="lg"
              hue="white"
              shade="000"
            />
          </Button>
        </Nav>
        <Info>
          <SvgIcon SvgComponent={Lock} size="md" hue="tusk" shade="080" />{" "}
          Secure Checkout
        </Info>
      </Wrap>
    </Drawer>
  )
}

export default CompareDrawer

const Nav = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  position: sticky;
  bottom: 0;
  background: white;
  box-shadow: 0 -4px 4px 0px #2121210f;
  /* justify-content */

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
    button {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
`

const Info = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  color: #9ba5ae;
  padding: 15px 10px 50px 10px;
  border-top: 2px dashed #54606e17;
  span.icon {
    margin-right: 3px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    display: flex;
  }
`
const Button = styled.button`
  text-transform: uppercase;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  width: 100%;
  transition: 0.2s all ease-in-out;
  margin: 0;
  padding: 0;
  background: #ed1c24;
  color: white;

  &:hover {
    &.left {
      transform: translateX(-3px);
    }
    &.right {
      transform: translateX(3px);
    }
  }
  span.icon {
    background: #d91a1f;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 15px;
  }

  &.secondary {
    background-color: white;
    color: #3e4b5c;
    box-shadow: inset 0px 0px 0px 2px #3e4b5c;
    margin-right: 20px;
    span.icon {
      box-shadow: inset 0px 0px 0px 2px #3e4b5c;
      background-color: white;
    }
  }
`

const Card = styled.div`
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.41);
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  .gatsby-image-wrapper {
    max-height: 200px;
    margin-right: 20px;
  }
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
    text-align: center;
  }
`
const Remove = styled.div`
  background: lightgrey;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  border-radius: 200px;
`
const Sub = styled.div`
  margin-top: 10px;
  margin-left: 10px;
`
const IconText = styled.div`
  display: flex;
  align-items: center;
  font-size: 26px;
`
const Heading = styled.div`
  padding: 10px 30px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  box-shadow: 0 1px 4px 1px #2121210f;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    transition: 0.2s all ease-in-out;
    cursor: pointer;
  }
`
const Wrap = styled.div``

const Items = styled.div`
  background: #f8f8f8;
  padding: 30px;
  overflow: hidden;
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const createLookup = (xs) => xs.reduce((a, c) => ({ ...a, [c]: true }), {})

const inLookup = (products, lookup) =>
  products.filter(({ wordpress_id }) => lookup[wordpress_id])

// ==================
//        QUERY
// ==================

const COMPARE_PRODUCTS = graphql`
  {
    ## ProductFilter: All beds and mattresses products
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
        categories: { elemMatch: { slug: { in: ["beds", "mattresses"] } } }
      }
    ) {
      nodes {
        ...ProductCardInfo
      }
    }
  }
`
