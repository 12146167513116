import { useEffect } from "react"

const useBodyScrollLock = (shouldLock) => {
  useEffect(() => {
    if (isBrowser()) shouldLock ? lock() : allow()
  }, [shouldLock])
}

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================
const isBrowser = () => typeof window !== "undefined"
const lock = () => (document.body.style.overflow = "hidden")
const allow = () => (document.body.style.overflow = "")

export default useBodyScrollLock
