import React, { useContext, memo } from "react"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ShoppingCart from "../../zzz/atoms/icons/files/shopping-cart.jsx"
import CartContext from "../../context/CartContext"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const CartIcon = memo(() => {
  const { cartCount } = useContext(CartContext)

  return (
    <>
      {cartCount > 0 && <span className="notify">{cartCount}</span>}
      <SvgIcon
        SvgComponent={ShoppingCart}
        hue="danger"
        shade="fontColor"
        size="lg"
      />
      Cart
    </>
  )
})

export default CartIcon
