import React from "react"
import styled from "styled-components"

import LinkWrapper from "../../../utils/linkWrapper"

export const ButtonGrid = styled(LinkWrapper)`
  cursor: pointer;
  display: grid;
  grid-template-columns: 28px 1fr;
  margin: 0 5px;
  align-items: center;
  color: ${({ theme }) => theme.colors.tusk["020"]};
  transition: all 200ms ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.white["000"]};
    transform: translateY(-1px);
  }
`

export default ButtonGrid
