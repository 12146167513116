import React from "react"
import styled from "styled-components"

import { Responsive, windowSizes } from "../../../utils/responsive"

import DesktopHeader from "./DesktopHeader"
import MobileMenu from "../../molecules/mobileMenu"
import Announcement from "../../molecules/announcement"

const Header = () => {
  return (
    <HeaderWrapper>
      <Responsive maxWidth={windowSizes.mobileMedium}>
        <MobileMenu />
      </Responsive>
      <Announcement />
      <Responsive minWidth={windowSizes.mobileMedium}>
        <DesktopHeader />
      </Responsive>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
    height: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding-bottom: 70px;
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
`

const MobileHeaderFallback = styled.div`
  height: 70px;
  background: #1a293c;
`

const DesktopHeaderFallback = styled.div`
  height: 219px;
  background: #1a293c;
`
