import React from "react"

const SocialLinkedIn = ({ fill }) => (
  <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.12 14H.22V4.66h2.9V14zM1.7 3.37c-.46 0-.86-.16-1.19-.5C.17 2.54 0 2.15 0 1.7 0 1.23.17.83.5.5.83.17 1.23 0 1.69 0c.46 0 .85.17 1.18.5.34.33.5.73.5 1.19 0 .46-.16.85-.5 1.18-.33.34-.72.5-1.18.5zM14 14h-2.9V9.44c0-.75-.07-1.3-.2-1.63-.22-.56-.67-.84-1.34-.84-.66 0-1.13.25-1.4.75-.21.37-.32.93-.32 1.65V14H4.97V4.66h2.78v1.28h.03c.21-.42.54-.76 1-1.03.5-.34 1.08-.5 1.75-.5 1.36 0 2.3.42 2.84 1.28.42.68.63 1.75.63 3.18V14z"
      fill={fill}
    />
  </svg>
)

export default SocialLinkedIn
