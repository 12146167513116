import React from "react"

const BellIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43.925"
    height="45.019"
    viewBox="0 0 43.925 45.019"
  >
    <g transform="translate(-0.805)">
      <circle
        fill="#ffe266"
        cx="4.748"
        cy="4.748"
        r="4.748"
        transform="translate(15.499 36.026) rotate(-18.725)"
      />
      <path
        fill="#efaa66"
        d="M186.04,386.866a4.77,4.77,0,0,0-.884.083,3.145,3.145,0,1,1-3.78,3.78,4.748,4.748,0,1,0,4.664-3.864Z"
        transform="translate(-164.511 -352.621)"
      />
      <path
        fill="#ffe266"
        d="M314.116,9.605a2.752,2.752,0,0,0-3.759,1.007l-2.28,3.949a2.752,2.752,0,0,0,4.766,2.752l2.28-3.949A2.752,2.752,0,0,0,314.116,9.605Z"
        transform="translate(-279.737 -8.419)"
      />
      <path
        fill="#ffe266"
        d="M56.192,84.77c-1.881-2.983,4.567-16.183,4.65-16.351a13.788,13.788,0,1,0-24.713-12.2c-.179.363-6.391,13.6-9.962,13.73Z"
        transform="translate(-23.116 -43.866)"
      />
      <path
        fill="#efaa66"
        d="M54.809,54.707a13.377,13.377,0,0,0-2.272-.875,10.353,10.353,0,0,1,2.649,12.7c-.033.067-1.429,2.925-2.484,5.877a5.222,5.222,0,0,1-7.227,2.944l-13.3-6.565c-1.971,3.227-4.292,6.306-6.005,6.37L56.192,89.971c-1.881-2.983,4.567-16.183,4.65-16.351a14.042,14.042,0,0,0-6.033-18.913Z"
        transform="translate(-23.116 -49.067)"
      />
      <g transform="translate(17.117 8.645)">
        <path
          fill="#fff"
          d="M185.965,101.881a.885.885,0,0,1-.688-1.442,12.6,12.6,0,0,1,2.911-2.63.885.885,0,0,1,.968,1.482,10.831,10.831,0,0,0-2.5,2.261A.883.883,0,0,1,185.965,101.881Z"
          transform="translate(-185.081 -97.665)"
        />
      </g>
      <g transform="translate(22.52 7.083)">
        <path
          fill="#fff"
          d="M247,82.053a.885.885,0,0,1-.275-1.727c.336-.11.682-.207,1.028-.288a.885.885,0,0,1,.4,1.724c-.3.069-.591.151-.878.246A.888.888,0,0,1,247,82.053Z"
          transform="translate(-246.119 -80.016)"
        />
      </g>
      <path
        fill="#ffe266"
        d="M41.951,296.989,14.421,283.4a2.158,2.158,0,0,1-.98-2.89l.035-.07a2.158,2.158,0,0,1,2.89-.98l27.529,13.585a2.158,2.158,0,0,1,.98,2.89l-.035.07A2.158,2.158,0,0,1,41.951,296.989Z"
        transform="translate(-11.315 -254.523)"
      />
      <g transform="translate(17.302 35.438)">
        <path
          fill="#d0844b"
          d="M195.631,405.85a.881.881,0,0,1-.391-.092l-7.568-3.734a.885.885,0,0,1,.783-1.588l7.568,3.734a.885.885,0,0,1-.392,1.679Z"
          transform="translate(-187.179 -400.345)"
        />
      </g>
      <path
        fill="#fef2a8"
        d="M4.28,96.3l.727,2.045a.328.328,0,0,0,.171.192l1.816.818a.362.362,0,0,1,0,.64l-1.816.818a.328.328,0,0,0-.171.192l-.727,2.045a.3.3,0,0,1-.569,0l-.727-2.045a.328.328,0,0,0-.171-.192L1,100a.362.362,0,0,1,0-.64l1.816-.818a.328.328,0,0,0,.171-.192L3.711,96.3a.3.3,0,0,1,.569,0Z"
        transform="translate(0 -87.58)"
      />
      <path
        fill="#fef2a8"
        d="M115.508.161l.539,1.517a.243.243,0,0,0,.126.142l1.347.607a.269.269,0,0,1,0,.475l-1.347.607a.243.243,0,0,0-.126.142l-.539,1.517a.219.219,0,0,1-.422,0l-.539-1.517a.243.243,0,0,0-.126-.142L113.073,2.9a.269.269,0,0,1,0-.475l1.347-.607a.243.243,0,0,0,.126-.142l.539-1.517a.219.219,0,0,1,.422,0Z"
        transform="translate(-102.201)"
      />
      <g transform="translate(4.94 3.681)">
        <circle
          fill="#ffe266"
          cx="1.588"
          cy="1.588"
          r="1.588"
          transform="matrix(0.217, -0.976, 0.976, 0.217, 36.001, 4.606)"
        />
        <circle fill="#ffe266" cx="0.685" cy="0.685" r="0.685" />
      </g>
    </g>
  </svg>
)

export default BellIcon
