import React, { useContext } from "react"

import BranchContext from "../../context/BranchContext"

import ButtonGrid from "../../zzz/molecules/headerSkinny/buttonGrid"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"

import MapMarkerIcon from "../../zzz/atoms/icons/files/map-marker.jsx"

export const BranchSelectButton = ({
  className = "",
  hue = "sky",
  shade = "000",
}) => {
  const {
    selectedBranch,
    setBranchSelectPopupOpen,
    selectedBranchObject,
  } = useContext(BranchContext)
  return (
    <ButtonGrid
      className={className}
      as="div"
      onClick={() => {
        setBranchSelectPopupOpen(true)
      }}
    >
      <SvgIcon SvgComponent={MapMarkerIcon} hue={hue} shade={shade} />
      {!selectedBranch
        ? "Select Store"
        : selectedBranchObject?.branchName || `Store selected`}
    </ButtonGrid>
  )
}

export default BranchSelectButton
