import React from "react"
import { Link } from "gatsby"

const httpStart = "http"
// const sizeUndefined = "?size=undefined"
const sizeUndefined = "?option=undefined"
export const LinkWrapper = ({
  children,
  to,
  as,
  doNotPrefetch = false,
  ...args
}) => {
  try {
    if (to) {
      if (to == "undefined") {
        console.error("LinkWrapper undefined to = ", to)
        to = "/"
      } else if (to.endsWith(sizeUndefined)) {
        to = to.slice(0, to.length - sizeUndefined.length)
      } else if (to.startsWith(httpStart)) {
        //   console.error("LinkWrapper to starts with http:/ or https:/ to = ", to)
        //   to = to.slice(httpStart.length)
      } else if (to.charAt(0) != "/") {
        to = `/${to}`
      }
    } else {
      to = "/"
    }
    if (to.toLowerCase().includes("undefined")) {
      console.error("LinkWrapper caught undefined in url. to = ", to)
    }
    if (!to.endsWith("/") && !to.includes("?") && !to.includes("#")) {
      to = `${to}/`
    }
  } catch (err) {
    const error_msg = `
      LinkWrapper caught error: ${JSON.stringify(err, null, 2)}
      LinkWrapper caught error on to: ${JSON.stringify(to, null, 2)}
      LinkWrapper caught error on args: ${JSON.stringify(args, null, 2)}
    `
    console.log(error_msg)
    throw new Error(error_msg)
  }

  // Disabler to prevent prefetching of heavy, rarely used pages
  if (doNotPrefetch === true) {
    return (
      <a href={to} {...args}>
        {children}
      </a>
    )
  }

  if (as != undefined) {
    if (as == LinkWrapper) {
      as = Link
    }
    return (
      <Link to={to} as={as} {...args}>
        {children}
      </Link>
    )
  }
  return (
    <Link to={to} {...args}>
      {children}
    </Link>
  )
}

export default LinkWrapper
