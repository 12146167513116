import React, { memo, useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import searchIcon from "../../images/icons/search-dark.svg"
import LinkWrapper from "../../utils/linkWrapper"

import SearchDropdown from "./SearchDropdown"
import SearchInput, { filterSearchResults } from "./SearchInput"

const searchPlaceholderText = "Search for any brand, size or comfort level..."
const resultTypes = [
  { title: "Products", type: "products" },
  { title: "Brands", type: "brands" },
  { title: "Pages", type: "pages" },
  { title: "Blogs", type: "posts" },
]

const Searchbar = memo(() => {
  const [index, setIndex] = useState(false)
  const [searchContainerFocus, setSearchContainerFocus] = useState(false)
  const [searchResultClicked, setSearchResultClicked] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResponse, setSearchResponse] = useState(null)
  const [searchResults, setSearchResults] = useState({
    posts: [],
    brands: [],
    products: [],
    pages: [],
  })

  // Only load the search client when search bar is selectsed
  useEffect(() => {
    if (searchContainerFocus && !index) {
      import("@elastic/app-search-javascript")
        .then(({ createClient }) => {
          setIndex(
            createClient({
              searchKey: process.env.GATSBY_ELASTICSEARCH_API_KEY,
              endpointBase: process.env.GATSBY_ELASTICSEARCH_API_URL,
              engineName: process.env.GATSBY_ELASTICSEARCH_INDEX_NAME,
            })
          )
        })
        .catch((error) =>
          console.log("An error occurred while loading AppSearchClient", error)
        )
    }
  }, [searchContainerFocus])

  // Perform search on the input field string
  // We avoid many states here because they slow things down
  const performSearch = async (event) => {
    event.persist()
    if (index) {
      const newQuery = event.target.value
      const options = {
        page: { size: 15 },
      }
      if (!newQuery || newQuery.length < 3) {
        setSearchResults(filterSearchResults(null))
      } else {
        index
          .search(newQuery, options)
          .then((response) => {
            setSearchResults(filterSearchResults(response))
            setSearchResponse(response)
          })
          .catch((error) => console.log(error.errorMessages))
      }
      setSearchQuery(newQuery)
    }
  }

  // Handle a focus loss on the results. Check if a result was clicked
  const handleClick = (e) => {
    if (e?.relatedTarget?.id?.startsWith?.("search-result-hit-")) {
      setSearchResultClicked(e?.relatedTarget?.id?.toString?.() ?? false)
    } else {
      setSearchContainerFocus(false)
    }
  }

  const searchResultCount =
    searchResults.products.length +
    searchResults.brands.length +
    searchResults.posts.length +
    searchResults.pages.length

  return (
    <StyledSearchBar>
      <div
        className="ElasticSearchContainer"
        onFocus={() => setSearchContainerFocus(true)}
        onBlur={handleClick}
      >
        <SearchInput
          searchPlaceholderText={searchPlaceholderText}
          performSearch={performSearch}
        />
        <ElasticSearchHitsDropdownStyling>
          {resultTypes.map(({ title, type }) => (
            <SearchDropdown
              key={title}
              title={title}
              searchResults={searchResults[type]}
              searchResultClicked={searchResultClicked}
              searchContainerFocus={searchContainerFocus}
              client={index}
              searchQuery={searchQuery}
              searchResponse={searchResponse}
            />
          ))}
          {!searchContainerFocus || !(searchResultCount >= 15) ? null : (
            <StyledLinkWrapper
              id={`search-result-hit-more`}
              key="more"
              to={`/search/?query=${searchQuery}`}
              className={
                searchResultClicked === `search-result-hit-more`
                  ? `clicked`
                  : `not-clicked`
              }
            >
              <p>
                <em>Click here to view more results</em>
              </p>
            </StyledLinkWrapper>
          )}
        </ElasticSearchHitsDropdownStyling>
      </div>
    </StyledSearchBar>
  )
})

export default Searchbar

// ==========
// 	 STYLESSearchDropdown
// ==========

const StyledSearchBar = styled.div`
  width: 100%;
  position: relative;
  div.ElasticSearchContainer {
    width: 100%;
    position: relative;
    .ElasticSearchInputField {
      width: 100%;
      input {
        padding: 15px 45px 15px 15px;
        width: 100%;
        border-radius: 3px;
        border-width: 1px;
        border-style: solid;
        border-color: #3e4b5c;
        background-color: #223247 !important;
        color: white !important;
        font-size: 16px;
        background-image: url(${searchIcon});
        background-repeat: no-repeat;
        background-position: 97% center;
        -webkit-text-fill-color: white !important;
        &::placeholder {
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }
`
const ElasticSearchHitsDropdownStyling = styled.div`
  position: absolute;
  z-index: 101;
  width: 100%;
  padding: 0 1px;
  box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  background-color: white;
  h4 {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #223247;
    font-weight: 600;
    font-size: 18px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    top: 60px;
  }
`

const StyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  &:hover {
    box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 101;
  }
  p {
    margin: 0 0 0 20px;
    color: black;
    font-style: italics;
  }
`
