import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

function PopiBanner() {
  const [dismissed, setDismissed] = useState(true)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setDismissed(window.localStorage.getItem("dismissPopi"))
    }
  }, [])

  function closeModal() {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("dismissPopi", true)
    }
    setDismissed(true)
  }

  // Don't return null as it could mess up the styled components
  if (dismissed) {
    return <EmptyBox />
  }

  return (
    <PopiContainer>
      <Text to="/terms-and-conditions/">
        This website uses cookies to collect data and personalise your
        experience. By continuing to use the site you agree to allow us to
        collect information using cookies
      </Text>
      <AcceptButton onClick={closeModal}>ACCEPT</AcceptButton>
    </PopiContainer>
  )
}

export default PopiBanner

const EmptyBox = styled.div`
  display: none;
`
const PopiContainer = styled.div`
  display: flex; //${({ dismissed }) => (dismissed ? "none" : "flex")};
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 5;
  justify-content: space-between;
  background-color: #283f53;
  padding: 20px 40px;
`
const Text = styled(Link)`
  align-self: center;
  color: #b6c9e3;
  cursor: pointer;
  margin-right: 20px;
  &:hover {
    color: white;
  }
`
const AcceptButton = styled.div`
  align-self: center;
  color: white;
  padding: 10px;
  border-radius: 3px;
  background-color: #ed1c24;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
  }
`
