import React from "react"

const IconArrowRight = ({ fill }) => (
  <svg width="14" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.8.75a.33.33 0 01.46 0l5.77 5.77c.13.13.13.33 0 .46l-5.77 5.77a.33.33 0 01-.47 0l-.19-.19a.33.33 0 010-.46l4.88-4.89H1.2a.33.33 0 01-.32-.32V6.6c0-.18.14-.32.32-.32h10.28L6.6 1.4a.33.33 0 010-.46l.2-.2z"
      fill={fill}
    />
  </svg>
)

export default IconArrowRight
