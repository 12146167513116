import React, { useContext, useState, useEffect, useRef } from "react"
import styled from "styled-components"

import { replaceAll } from "../../lib/stringHandling"

import BranchContext from "../../context/BranchContext"
import ProductContext from "../../context/ProductContext"

import BranchSelector from "./BranchSelector"

const BranchSelectPopup = ({ className = "" }) => {
  const {
    branchSelectPopupOpen,
    setBranchSelectPopupOpen,
    selectedBranch,
  } = useContext(BranchContext)

  const { currentProduct } = useContext(ProductContext)

  const [scrollPosition, setScrollPosition] = useState(0)

  const modalBackgroundRef = useRef()
  const modalInnerRef = useRef()

  useEffect(() => {
    if (typeof window != "undefined") {
      const bodyNode = document.querySelector("body")
      const fixedClassName = " menu-fixed"
      if (branchSelectPopupOpen) {
        const newScrollPosition = window.pageYOffset
        bodyNode.className += fixedClassName
        bodyNode.style.top = `-${newScrollPosition}px`
        setScrollPosition(newScrollPosition)
      } else {
        bodyNode.className = replaceAll(bodyNode.className, fixedClassName, "")
        bodyNode.style.removeProperty("top")
        window.scrollTo(0, scrollPosition)
      }
    }
    if (branchSelectPopupOpen) {
      modalBackgroundRef?.current?.scrollTo?.(0, 0)
    }
  }, [branchSelectPopupOpen])

  const handleBackgroundClick = (event) => {
    if (modalInnerRef?.current) {
      if (!modalInnerRef?.current?.contains?.(event.target)) {
        setBranchSelectPopupOpen(false)
      }
    }
  }

  // Don't mount branch selector components if it's not open
  if (!branchSelectPopupOpen) {
    return null
  }

  return (
    <ModalBackgroundStyling
      ref={modalBackgroundRef}
      className={`${branchSelectPopupOpen ? "show" : "hide"} ${className}`}
      onMouseDown={handleBackgroundClick}
    >
      <ModalInnerStyling ref={modalInnerRef}>
        <StyledButton
          type="button"
          onClick={() => setBranchSelectPopupOpen(false)}
        >
          &times;
        </StyledButton>
        {!currentProduct ? null : (
          <StyledProductContainer>
            {!currentProduct?.image?.src ? null : (
              <SyledProductImage src={currentProduct?.image?.src} />
            )}
            {!currentProduct?.name ? null : (
              <StyledProductName
                dangerouslySetInnerHTML={{ __html: currentProduct?.name }}
              />
            )}
          </StyledProductContainer>
        )}
        <StyledHeading>
          {`${selectedBranch ? "Change" : "Select"} store`}
        </StyledHeading>
        <ModalScrollAreaContainer>
          <ModalScrollAreaStyling>
            <BranchSelector />
          </ModalScrollAreaStyling>
        </ModalScrollAreaContainer>
      </ModalInnerStyling>
    </ModalBackgroundStyling>
  )
}

export default BranchSelectPopup

// ===============
//     STYLES
// ===============
const ModalBackgroundStyling = styled.div`
  &.show {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: black;
  }
  &.hide {
    display: none;
  }
`

const ModalInnerStyling = styled.div`
  z-index: 1000;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 75vh;
  background-color: white;
  border-radius: 5px;
  justify-content: space-between;
  box-shadow: 0 0 0px 0.15px white;
  &,
  button,
  div {
    color: ${({ theme }) => theme?.colors?.black1};
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 85%;
    height: 85vh;
  }
`
// This container rounds the scrollbard bottom-right corner
const ModalScrollAreaContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
`

const ModalScrollAreaStyling = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  border-radius: 0 0 5px 5px;
  overflow-x: hidden;
`

const StyledButton = styled.button`
  position: absolute;
  color: ${({ theme }) => theme?.colors.black1};
  background-color: white;
  border: 1px solid ${({ theme }) => theme?.colors?.black1};
  top: -17px;
  right: -17px;
  border-radius: 100%;
  border-color: white;
  min-width: unset;
  padding: 2.5px 0 0;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme?.colors?.ruby["080"]};
    border-color: ${({ theme }) => theme?.colors?.ruby["080"]};
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`

const StyledHeading = styled.h2`
  background-color: #071322;
  color: white;
  font-weight: 500;
  font-family: Roboto;
  font-size: 25px;
  padding: 23px 50px;
  line-height: 1;
  letter-spacing: 1px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 23px 20px;
  }
`

const StyledProductContainer = styled.div`
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const SyledProductImage = styled.img`
  height: 100%;
  margin-left: 50px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-left: 20px;
  }

  @media (max-width: 350px) {
    margin-left: 10px;
  }
`
const StyledProductName = styled.div`
  font-size: 30px;
  font-family: Roboto;
  color: #223247;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30px;
  margin-right: 50px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-left: 20px;
    font-size: 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font-size: 20px;
    line-height: 25px;
  }

  @media (max-width: 350px) {
    font-size: 18px;
    margin-left: 10px;
  }
`
