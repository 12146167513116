import React from "react"

const SocialFacebook = ({ fill }) => (
  <svg width="9" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.4 16V8.84H0V6h2.4V3.75c0-1.19.34-2.1 1-2.77A3.62 3.62 0 016.07 0c.9 0 1.63.04 2.19.12v2.54h-1.5c-.56 0-.95.12-1.16.37-.16.21-.25.54-.25 1V6H8l-.38 2.84H5.34V16H2.41z"
      fill={fill}
    />
  </svg>
)

export default SocialFacebook
