import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const GridWrapper = styled.div`
  display: grid;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  padding: ${(props) => props.padding};
  grid-template-columns: ${(props) => props.columns};
  grid-gap: ${(props) => props.gutter};
  align-items: ${({ align }) => align};
  justify-items: ${({ justify }) => justify};
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 1245px) {
    &.grid {
      ${(props) =>
        props.narrowGutter ? `grid-gap: ${props.narrowGutter};` : ""}
    }
  }
}
  
`
const Grid = ({
  cols,
  gutter,
  narrowGutter = false,
  spacing,
  children,
  align,
  justify,
  padding = "0 20px",
}) => {
  let columns = ""
  let classes = ["grid"]
  if (spacing === "") {
    columns = `repeat(${cols}, 1fr)`
    classes = [...classes, `grid-cols-${cols}`]
  } else {
    columns = spacing
    classes = [...classes, `grid-custom-spacing`]
  }
  return (
    <GridWrapper
      columns={columns}
      gutter={gutter}
      narrowGutter={narrowGutter}
      className={classes.join(" ")}
      align={align}
      justify={justify}
      padding={padding}
    >
      {children}
    </GridWrapper>
  )
}

Grid.propTypes = {
  cols: PropTypes.any,
  spacing: PropTypes.string,
  gutter: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
}

Grid.defaultProps = {
  cols: "1",
  spacing: "",
  gutter: "0",
  align: "center",
  justify: "flex-start",
}

export default Grid
