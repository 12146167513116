import React from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"
import { GatsbyImage } from "gatsby-plugin-image"
import { CreateLocalLink } from "../../../../lib/util"

const AboutMenu = ({ links, cta }) => {
  const fluidImage =
    cta.backgroundImage.imageFile.localFile.childImageSharp.gatsbyImageData
  return (
    <Wrap>
      <GatsbyImage image={fluidImage} alt="about-menu-image" />
      <Links>
        {links.map((node, i) => (
          <StyledLink
            forwardedAs={node.url === "#" ? "p" : "Link"}
            to={CreateLocalLink(node.url)}
            key={i}
            className="column-heading"
          >
            {node.label}
          </StyledLink>
        ))}
      </Links>
    </Wrap>
  )
}

export default AboutMenu

const StyledLink = styled(Link)`
  color: white;
`

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 40px 100px;
  grid-gap: 50px;
  img {
    border-radius: 3px;
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
