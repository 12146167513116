import React, { useContext } from "react"
import styled from "styled-components"
import CartContext from "../../../context/CartContext"

import SvgIcon from "../../atoms/icons/svg-icon"
import Bed from "../../atoms/icons/files/bed.jsx"
import Search from "../../atoms/icons/files/search.jsx"
import LocationPin from "../../atoms/icons/files/location-pin.jsx"
import UserCircle from "../../atoms/icons/files/user-circle.jsx"
import ShoppingCart from "../../atoms/icons/files/shopping-cart.jsx"

import LinkWrapper from "../../../utils/linkWrapper"

//=====================
//      MAIN
//=====================
const MobileTray = () => {
  const { cartCount, setCartDrawerOpen } = useContext(CartContext)

  // Function to open cart
  const onClickCartOpen = (e) => {
    setCartDrawerOpen(true)
    e.preventDefault() // Do not perform default "LinkWrapper" navigate
  }

  // Array of settings for mobile menu buttons
  const links = [
    {
      name: Bed,
      minor: Search,
      to: "/bed-finder/",
    },
    {
      name: LocationPin,
      to: "/contact-us/",
    },
    {
      name: Search,
      to: "/search/",
    },
    {
      name: UserCircle,
      to: "/my-account/",
    },
    {
      name: ShoppingCart,
      onClick: onClickCartOpen,
      alert: cartCount,
    },
  ]

  return <Container>{links.map(link)}</Container>
}

//=====================
//      HELPER
//=====================
const link = ({ name, minor, to, onClick, alert }) => (
  <StyledLink
    key={name}
    to={onClick ? null : to}
    onClick={onClick}
    as={onClick ? "div" : StyledLink}
  >
    <div className="container">
      {alert && <Notify>{alert}</Notify>}
      <SvgIcon SvgComponent={name} hue="white" shade="000" size="md" />
      {minor && (
        <SvgIcon
          className="shifted"
          SvgComponent={minor}
          hue="white"
          shade="000"
          size="xs"
        />
      )}
    </div>
  </StyledLink>
)

export default MobileTray

//=====================
//      STYLES
//=====================
const Container = styled.div`
  background: ${({ theme }) => theme.colors.midnight["080"]};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  z-index: 1;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    display: grid;
  }
`

const StyledLink = styled(LinkWrapper)`
  padding: 10px;
  text-align: center;
  .container {
    position: relative;
    display: inline-block;
  }
  .shifted {
    position: absolute;
    top: 0px;
    right: -5px;
  }
  &:hover,
  &[aria-current="page"] {
    background: ${({ theme }) => theme.colors.midnight["100"]};
  }
`

const Notify = styled.div`
  position: absolute;
  top: 0px;
  right: -5px;
  background: red;
  color: white;
  border-radius: 100px;
  padding: 2px;
  z-index: 1;
  width: 17px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: initial;
`
