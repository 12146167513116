import React from "react"

const Lock = ({ fill }) => (
  <svg width="13" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.125 11.484a.548.548 0 01-.547-.546v-1.75c0-.301.246-.547.547-.547.3 0 .547.246.547.547v1.75c0 .3-.246.546-.547.546zm6.125-4.046v5.25c0 .724-.588 1.312-1.313 1.312H1.312A1.313 1.313 0 010 12.688v-5.25c0-.725.588-1.313 1.312-1.313h.438v-1.75A4.379 4.379 0 016.139 0C8.556.008 10.5 1.999 10.5 4.416v1.709h.437c.725 0 1.313.588 1.313 1.313zM2.625 6.125h7v-1.75c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5v1.75zm8.75 6.563v-5.25A.439.439 0 0010.937 7H1.312a.439.439 0 00-.437.438v5.25c0 .24.197.437.437.437h9.625c.241 0 .438-.197.438-.437z"
      fill={fill}
    />
  </svg>
)

export default Lock
