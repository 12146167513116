import React from "react"

const IconBed = ({ fill }) => (
  <svg width="18" height="11" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.187 7.5A2.19 2.19 0 012 5.313a2.19 2.19 0 012.187-2.188 2.19 2.19 0 012.188 2.188A2.19 2.19 0 014.187 7.5zm0-3.5a1.314 1.314 0 000 2.625c.724 0 1.313-.589 1.313-1.312C5.5 4.589 4.911 4 4.187 4zm10.5-.875a3.062 3.062 0 013.063 3.063v4.593c0 .121-.098.219-.219.219h-.437a.219.219 0 01-.219-.219V9.25H1.125v1.531c0 .121-.098.219-.219.219H.47a.219.219 0 01-.219-.219V.72C.25.598.348.5.469.5h.437c.121 0 .219.098.219.219v7.656H7.25V3.563c0-.242.196-.438.437-.438h7zm2.188 5.25V6.188A2.19 2.19 0 0014.687 4H8.125v4.375h8.75z"
      fill={fill}
    />
  </svg>
)

export default IconBed
