import React, { useState, useContext, useMemo, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ChevronLeft from "../../zzz/atoms/icons/files/chevron-left.jsx"
import ChevronRight from "../../zzz/atoms/icons/files/chevron-right.jsx"
import CartContext from "../../context/CartContext"
import { formatCurrencyInt, getShippingClassInCart } from "../../lib/util"
import ButtonLoader from "./ButtonLoader"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  includes,
  split,
  slugify,
  replaceAll,
  titleCase,
} from "../../lib/stringHandling"
import BranchContext from "../../context/BranchContext"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const RelatedProducts = ({ setMessage }) => {
  const { selectedBranch } = useContext(BranchContext)
  const graphqlData = useStaticQuery(CART_PRODUCTS)
  const products = graphqlData.allWcProducts.nodes

  const [active, setActive] = useState(0)
  const next = () =>
    setActive((p) => (p === showProducts.length - 1 ? 0 : p + 1))
  const prev = () => setActive((p) => (p === 0 ? showProducts.length - 1 : 0))
  const {
    cartDrawerOpen,
    setCartDrawerOpen,
    cartContents,
    addToCart,
    cartReady,
  } = useContext(CartContext)

  const comfortTrialItems =
    cartContents
      ?.map((item) => {
        if (item.bundled_by) {
          return null
        } else {
          let productName = item.product_name
          let productSku = item.tracking_sku ? item.tracking_sku : item.sku
          // If product_name == product_title, it means the size was not appended
          // in the backend so attempt to do so now
          if (productName === item.product_title) {
            productName = `${productName}${
              item.size
                ? ` - ${titleCase(replaceAll(item.size, "-", " "))}`
                : ""
            }`
          }
          const shippingClassForLocation = getShippingClassInCart(
            cartContents,
            item,
            selectedBranch
          )

          const comfortTrialApplicable = item?.comfort_trial_available == "Yes"
          const productSize = slugify(item?.size)
          const comfortTrialQualified = comfortTrialApplicable
            ? cartContents
                .filter((it) =>
                  it.categories?.some(
                    (cat) => cat.slug == "mattress-protectors"
                  )
                )
                .some((mattProtector) =>
                  slugify(mattProtector?.size)?.includes(productSize)
                )
            : false

          if (comfortTrialApplicable) {
            return {
              ...item,
              productName,
              productSku,
              shippingClassForLocation,
              productSize,
              comfortTrialApplicable,
              comfortTrialQualified,
            }
          }
        }
      })
      ?.filter((i) => !!i) || []

  const showProducts = useMemo(() => {
    let cartSizes = ["single"] // default value

    let UnqualifiedItemsInCart = []
    let UnqualifiedItemSizes = []
    if (comfortTrialItems && comfortTrialItems.length) {
      comfortTrialItems.forEach((item) => {
        if (!item.comfortTrialQualified) {
          UnqualifiedItemsInCart.push(item)
        }
      })
      UnqualifiedItemSizes = UnqualifiedItemsInCart.map((item) =>
        simplifySize(item.size)
      )
    }

    if (cartContents && cartContents.length) {
      cartSizes = cartContents.map((item) => simplifySize(item.size))
    }

    if (UnqualifiedItemSizes && UnqualifiedItemSizes.length) {
      let newCartSizes = []
      cartSizes.forEach((size) => {
        if (
          UnqualifiedItemSizes.includes(size) &&
          !newCartSizes.includes(size)
        ) {
          newCartSizes.push(size)
        }
      })
      if (newCartSizes && newCartSizes.length) {
        cartSizes = newCartSizes
      }
    }

    const relatedVariations = products.filter(
      (product) => product.type == "variable"
    )
    const relatedSimple = products.filter((product) => product.type == "simple")
    const productsToShow = []
    if (relatedVariations && relatedVariations.length) {
      relatedVariations.forEach((pr) => {
        return pr.product_variations.forEach((vr) => {
          // Check if the variation is a size in one of the cart-sizes
          if (
            vr.attributes.some((att) =>
              cartSizes.some((size) => size == simplifySize(att.option))
            )
          ) {
            productsToShow.push({
              ...pr,
              ...vr,
              variation_id: vr.id,
            })
          }
        })
      })
    }
    if (relatedSimple && relatedSimple.length) {
      relatedSimple.forEach((pr) => {
        // Check if the variation is a size in one of the cart-sizes
        productsToShow.push({
          product_id: pr.wordpress_id,
          ...pr,
        })
      })
    }

    if (UnqualifiedItemsInCart.length == 0) {
      return []
    } else {
      return productsToShow
    }
  }, [cartContents])

  useEffect(() => {
    if (active > showProducts.length - 1) {
      setActive(0)
    }
  }, [showProducts])

  const handleAddToCart = async (product) => {
    setMessage("")

    addToCart(product).catch((error) => {
      console.log("Failed to add: ", error)
      if (error && error.message) {
        setMessage(error.message)
      } else {
        setMessage("Failed to complete action")
      }
    })
  }

  if (showProducts.length == 0) {
    return null
  }

  return (
    <Container>
      <Title>
        Add one of the following to qualify for the
        <br />
        <strong>100 Night Comfort Trial</strong>:
      </Title>
      <Grey>
        <Row length={showProducts.length} active={active}>
          {showProducts.map((product, index) => {
            const size =
              product.attributes &&
              product.attributes.length &&
              product.attributes.find((at) => at.name == "Size")
            const image =
              product.image ||
              (product.images && product.images.length && product.images[0])
            return (
              <Card key={`${product.wordpress_id || product.id}-${index}`}>
                <GatsbyImage alt={product.name} image={getImage(image)} />
                <Text>
                  <Link
                    onClick={() => setCartDrawerOpen(false)}
                    to={`/product/${product.slug}`}
                  >
                    <h4>{product.name}</h4>
                  </Link>
                  {size && size.option ? (
                    <h5>{`Size: ${size.option}`}</h5>
                  ) : null}
                  <button
                    onClick={() => handleAddToCart(product)}
                    disabled={!cartReady}
                  >
                    {!cartReady ? (
                      <Text className="text">
                        <ButtonLoader />
                      </Text>
                    ) : (
                      <Text className="text">
                        {"R "}
                        {formatCurrencyInt.format(product.price)} - Add to cart
                      </Text>
                    )}
                  </button>
                </Text>
              </Card>
            )
          })}
        </Row>
        {showProducts.length > 1 && (
          <SvgIconWrapper
            size="md"
            className="left"
            SvgComponent={ChevronLeft}
            onClick={prev}
          />
        )}
        {showProducts.length > 1 && (
          <SvgIconWrapper
            size="md"
            className="right"
            SvgComponent={ChevronRight}
            onClick={next}
          />
        )}
      </Grey>
    </Container>
  )
}

// Check and return the fluid part if it's there
const getImage = (image) => {
  if (
    image &&
    image.localFile &&
    image.localFile.childImageSharp &&
    image.localFile.childImageSharp.gatsbyImageData
  ) {
    return image.localFile.childImageSharp.gatsbyImageData
  } else if (image && image.length) {
    return getImage(image[0])
  } else {
    return image
  }
}

// Change all sizes to the basic ones
const simplifySize = (size) => {
  let retSize = size
  // change ie King/King-XL to just King
  if (includes(retSize, "/")) {
    retSize = split(retSize, "/")[0]
  }
  retSize = slugify(retSize)
  // change ie king-king-xl to just king
  if (includes(retSize, "-")) {
    const splitSize = split(retSize, "-")
    if (splitSize[0] == splitSize[1]) {
      retSize = splitSize[0]
    }
  }
  retSize = replaceAll(retSize, "-xl", "") // remove -xl if it somehow survived
  return retSize
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Row = styled.div`
  display: flex;
  width: ${({ length }) => length * 100 + "%"};
  transition: 0.2s all ease-in-out;
  transform: translateX(
    ${({ length, active }) => (-100 / length) * active + "%"}
  );
`
const SvgIconWrapper = styled(SvgIcon)`
  margin: 5px;
`
const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Card = styled.div`
  background: white;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0 1px 2px 1px #7575754d;
  margin: 0 50px;
  display: grid;
  grid-template-columns: 1.5fr 3fr;
  grid-gap: 10px;
  text-align: left;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    text-align: center;
  }

  h4 {
    font-weight: 500;
  }

  img {
    display: block;
    border: 1px solid #c1c1c1;
    border-radius: 3px;
  }

  button {
    background: #273243;
    border-radius: 3px;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;
  }
`
const Container = styled.div`
  text-align: center;
  position: relative;
  overflow: hidden;
  .icon {
    position: absolute;
    display: block;
    top: calc(50% + 12px);
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    &:hover {
      transform: translateX(-2px);
      transform: translateX(2px);
    }
    &.left {
      &:hover {
        transform: translateX(-2px);
      }
      left: 5px;
    }
    &.right {
      right: 5px;
      &:hover {
        transform: translateX(2px);
      }
    }
  }
`
const Title = styled.div`
  margin-bottom: 15px;
  strong {
    font-weight: bold;
  }
`
const Grey = styled.div`
  background: #f8f8f8;
`

export default RelatedProducts

// ===============
// 	   QUERY
// ===============

const CART_PRODUCTS = graphql`
  {
    ## ProductFilter: All cart accessories
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
        acf: { cart_products: { eq: "Yes" } }
      }
    ) {
      nodes {
        wordpress_id
        type
        slug
        name
        price
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 250
                layout: CONSTRAINED
                breakpoints: [100]
              )
            }
          }
        }
        categories {
          name
        }
        acf {
          cart_products
          comfort_trial_available
        }
        product_variations {
          id
          price
          size
          attributes {
            name
            option
          }
        }
      }
    }
  }
`
