import React from "react"

const IconArrowLeft = ({ fill }) => (
  <svg width="14" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.2 12.75a.33.33 0 01-.46 0L.97 6.98a.33.33 0 010-.46L6.74.75a.33.33 0 01.47 0l.19.19c.13.13.13.34 0 .46L2.52 6.3H12.8c.18 0 .32.14.32.32v.28c0 .18-.14.32-.32.32H2.52L7.4 12.1c.13.12.13.33 0 .46l-.2.2z"
      fill={fill}
    />
  </svg>
)

export default IconArrowLeft
