import "./layout.css"
import React, { useEffect, useLayoutEffect, useContext } from "react"

import PropTypes from "prop-types"

import AuthContext from "../../context/AuthContext"
import BranchContext from "../../context/BranchContext"
import CartContext from "../../context/CartContext"
import ProductContext from "../../context/ProductContext"
import PushNotificationContext from "../../context/PushNotification/PushNotificationContext"

import ThemeConfig from "./ThemeConfig"

import { sendRetentionScience } from "../../services/RetentionScience"
import { getUser } from "../../services/auth"
import { setUtm } from "../../lib/util"

import Header from "../organisms/header"
import Footer from "../organisms/footer"
import { runIntercomFixes } from "../../services/intercom"
import CartDrawer from "../../components/CartDrawer/CartDrawer"
import CompareDrawer from "../../components/Products/CompareDrawer"
import BranchSelectPopup from "../../components/BranchSelect/BranchSelectPopup"
import FloatingBranchSelectButton from "../../components/BranchSelect/FloatingBranchSelectButton"
import CustomPushPermissionRequestPopup from "../../components/PushNotifications/CustomPushPermissionRequestPopup"
import PopiBanner from "./PopiBanner"

const Layout = ({
  children,
  location,
  product,
  blank = false,
  skipReSciTracking = false,
  className = "",
}) => {
  const { loggedInState, checkLoggedInState } = useContext(AuthContext)
  const { setBranchSelectPopupOpen } = useContext(BranchContext)
  const { setCartDrawerOpen } = useContext(CartContext)
  const { setCurrentProduct } = useContext(ProductContext)
  const { setPushNotificationLocation } = useContext(PushNotificationContext)

  // Boot intercom and run fixes
  runIntercomFixes({ location })

  // On load extract and save any UTM paramters
  useEffect(() => {
    setUtm()
  }, [])

  // Do basic tracking if loggedInState has been updated
  useEffect(() => {
    let userInfo = {}
    if (loggedInState !== null && !skipReSciTracking) {
      userInfo = loggedInState === true ? getUser() : {}
      // Unless we prevent it (like if we want to trigger the functions manually), do basic tracking
      if (!skipReSciTracking) {
        sendRetentionScience({ userInfo })
      }
    }
  }, [loggedInState])

  // On every navigate or path change, verify user auth token
  useLayoutEffect(() => {
    checkLoggedInState()
    setCartDrawerOpen(false)
    setBranchSelectPopupOpen(false)
    if (!location?.pathname?.startsWith?.("/product/")) {
      setCurrentProduct(null)
    }
    setPushNotificationLocation(location)
  }, [location])

  return (
    <ThemeConfig>
      {!blank && <PopiBanner />}
      {!blank && <CustomPushPermissionRequestPopup />}
      {!blank && <Header />}
      {!blank && <CartDrawer />}
      {!blank && <CompareDrawer />}
      {!blank && <BranchSelectPopup />}
      {!blank && <FloatingBranchSelectButton />}
      <main className={className}>{children}</main>
      {!blank && <Footer location={location} product={product} />}
    </ThemeConfig>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
