export const injectScript = ({
  id,
  parentElement,
  contents = "",
  src = "",
  onLoad = () => {},
}) => {
  // Check if script has been loaded to avoid multiples
  const oldScript = document.getElementById(id)
  let elementToInject = document.getElementsByTagName(parentElement)[0]
  if (oldScript) {
    onLoad() // run the callback again if the script is already loaded
  } else {
    var newScript = document.createElement("script")
    newScript.type = "text/javascript"
    newScript.async = true
    if (src != "") {
      newScript.src = src
    } else {
      newScript.text = contents
    }
    newScript.id = id
    newScript.addEventListener("load", onLoad)
    elementToInject.appendChild(newScript)
  }
}
