import React from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"
import ArrowRight from "../../../atoms/icons/files/arrow-right"

const CardLink = ({ title, to }) => {
  return (
    <StyledLink to={to}>
      {title} <ArrowRight fill="#223247" size="md" />
    </StyledLink>
  )
}

export default CardLink

const StyledLink = styled(Link)`
  border-radius: 4px;
  background: #d9e4f3;
  padding: 20px;
  color: #223247;
  min-height: 90px;
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
  &:hover {
    background: #fff;
    & > svg {
      #icon {
        fill: ${({ theme }) => theme.colors.ruby["060"]};
      }
    }
  }
  font-size: 18px;
  font-weight: 400;
  svg {
    transition: 0.2s all ease-in-out;
  }
`
