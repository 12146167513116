import React from "react"

const Phone = ({ fill }) => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.24.75c.45.1.76.5.76.95A14.3 14.3 0 011.7 16a.98.98 0 01-.95-.76L.03 12.1a.98.98 0 01.56-1.11l3.39-1.45a.98.98 0 011.14.27L6.5 11.5a10.94 10.94 0 004.98-4.99L9.8 5.12a.98.98 0 01-.28-1.14L10.98.6a.98.98 0 011.11-.56l3.15.72zM1.72 15C9.05 15 15 9.05 15 1.73L11.88 1l-1.43 3.35 2.27 1.86a12.08 12.08 0 01-6.5 6.5l-1.86-2.27L1 11.89 1.72 15z"
      fill={fill}
    />
  </svg>
)

export default Phone
