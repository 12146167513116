import React from "react"

const IconCross = ({ fill }) => (
  <svg
    width="9px"
    height="9px"
    viewBox="0 0 9 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon" transform="translate(-8.000000, -8.000000)" fill={fill}>
        <path
          d="M13.3030527,12.3749962 L16.6857388,15.7576824 C16.7710276,15.8429979 16.7710276,15.981639 16.6857388,16.0669278 L16.0669278,16.6857388 C15.981639,16.7710276 15.8429979,16.7710276 15.7576824,16.6857388 L12.3749962,13.3030527 L8.99204304,16.6857388 C8.90672754,16.7710276 8.76808651,16.7710276 8.68277101,16.6857388 L8.06398663,16.0669278 C7.97867112,15.981639 7.97867112,15.8429979 8.06398663,15.7576824 L11.4469398,12.3749962 L8.06398663,8.99204304 C7.97867112,8.90672754 7.97867112,8.76808651 8.06398663,8.68277101 L8.68306474,8.06398663 C8.76835354,7.97867112 8.90699456,7.97867112 8.99231007,8.06398663 L12.3749962,11.4469398 L15.7576824,8.06425365 C15.8429979,7.97896486 15.981639,7.97896486 16.0669278,8.06425365 L16.6857388,8.68306474 C16.7710276,8.76835354 16.7710276,8.90699456 16.6857388,8.99231007 L13.3030527,12.3749962 Z"
          id=""
        ></path>
      </g>
    </g>
  </svg>
)

export default IconCross
