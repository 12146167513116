import React, { useEffect, memo } from "react"
import styled from "styled-components"
import useBodyScrollLock from "../../../hooks/useBodyScrollLock"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Drawer = memo(({ children, open, closeFn, scroll }) => {
  useBodyScrollLock(open)

  const closeOnBrowserBackButton = (event) => closeFn()

  const closeFunctionWrapper = () => {
    history.go(-1)
    closeFn()
  }

  useEffect(() => {
    if (open) {
      history.replaceState(null, "", window.location.pathname)
      history.pushState(null, "", window.location.pathname)
      window.addEventListener("popstate", closeOnBrowserBackButton)
    }
    return () =>
      window.removeEventListener("popstate", closeOnBrowserBackButton)
  }, [open])

  const drawerVariants = {
    closed: { x: "105%", transition: { duration: 0.3, ease: "easeOut" } },
    open: { x: 0, transition: { duration: 0.3, ease: "easeIn" } },
  }

  const overlayVariants = {
    closed: {
      x: "-100%",
      opacity: 0,
      transition: {
        x: {
          delay: 0.3,
        },
        opacity: {
          duration: 0.3,
        },
      },
    },
    open: {
      x: 0,
      opacity: 1,
      transition: {
        x: {
          duration: 0.1,
        },
        opacity: {
          delay: 0.3,
        },
      },
    },
  }

  return (
    <>
      <Overlay
        overlayOpen={open}
        className="overlay"
        onClick={closeFunctionWrapper}
      />
      <SlideOut drawerOpen={open} scroll={scroll} onClick={() => {}}>
        {open ? children : null}
      </SlideOut>
    </>
  )
})

export default Drawer

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const Overlay = styled.div`
  transform: ${({ overlayOpen }) =>
    overlayOpen ? "translateX(0)" : "translateX(-100%)"};
  opacity: ${({ overlayOpen }) => (overlayOpen ? "1" : "0")};
  transition: ${({ overlayOpen }) =>
    overlayOpen
      ? "transform 0.1s ease-in-out 0s, opacity 0.3s ease-in-out 0.3s"
      : "transform 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out 0s"};
  position: fixed;
  width: 100%;
  background: #27314377;
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100vh;
  z-index: 10;
  top: 0;
`

const SlideOut = styled.div`
  transform: ${({ drawerOpen }) =>
    drawerOpen ? "translateX(0)" : "translateX(100%)"};
  transition: ${({ drawerOpen }) =>
    drawerOpen ? "transform 0.3s ease-out" : "transform 0.3s ease-out"};
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 60vw;
  background: white;
  z-index: 10;
  box-shadow: -1px 0 2px 3px #00000014;
  max-width: 600px;
  ${({ scroll }) => scroll && "overflow:scroll;"}
  .content p {
    margin: 1rem 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 80vw;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100vw;
  }
`
