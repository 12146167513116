import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Responsive, windowSizes } from "../../../utils/responsive"

import MobileTray from "./MobileTray"
import Grid from "../../layout/grid"
import SocialIcons from "../../atoms/socialIcons"
import Breadcrumbs from "../../atoms/breadcrumb"
import SvgIcon from "../../atoms/icons/svg-icon"
import Lock from "../../atoms/icons/files/lock.jsx"
import LogoVisa from "../../atoms/icons/files/logo-visa.jsx"
import LogoMastercard from "../../atoms/icons/files/logo-mastercard.jsx"
import PaymentMethodStrip from "./PaymentMethodStrip"
import Group from "./Group"
import LazyLoadWrapper from "../../../utils/lazyLoadWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Footer = ({ location, product }) => {
  const data = useStaticQuery(QUERY)
  const { pathname } = location
  const isHomePage = pathname === "/"

  const menularge =
    data.menularge &&
    data.menularge.nodes &&
    data.menularge.nodes[0].footerStructure
  const { payflexImage } = data
  const { swiftcomImage } = data

  return (
    <FooterWrapper>
      <Responsive maxWidth={windowSizes.mobileMedium}>
        <MobileTray />
      </Responsive>
      <LazyLoadWrapper>
        <PaymentMethodStrip />
        <SkinnyStrip>
          <Grid spacing="1fr auto" gutter="30px" align="center">
            <Breadcrumbs pathname={pathname} product={product} />
            <SocialIcons />
          </Grid>
        </SkinnyStrip>
        <FooterInner>
          <Grid cols="5" gutter="30px" align="flex-start">
            {menularge.map((node, i) => (
              <Group key={i} node={node} />
            ))}
          </Grid>
        </FooterInner>
        {/*<CovidStrip>*/}
        {/*  Visit the SA Department of Health's website for COVID-19 updates:{" "}*/}
        {/*  <a*/}
        {/*    href="https://www.sacoronavirus.co.za"*/}
        {/*    target="_blank"*/}
        {/*    rel="nofollow noopener noreferrer"*/}
        {/*  >*/}
        {/*    www.sacoronavirus.co.za*/}
        {/*  </a>*/}
        {/*</CovidStrip>*/}
        <CopyrightStrip>
          <Grid spacing="1fr auto" padding="0px">
            <CopyrightInfo>
              Copyright &copy; {new Date().getFullYear()} - Smith's Mattress
              Warehouse CC t/a The Mattress Warehouse - Registration Number:
              2007/083038/23 - All Rights Reserved.
            </CopyrightInfo>
            {/*<SecureLogos isHomePage={isHomePage}>*/}
            {/*  <SvgIcon SvgComponent={Lock} hue="tusk" shade="080" size="md" />*/}
            {/*  <Responsive minWidth={windowSizes.mobileMedium}>*/}
            {/*    <div className="txt-label">Secure</div>*/}
            {/*  </Responsive>*/}
            {/*  <GatsbyImage*/}
            {/*    image={payflexImage.childImageSharp.gatsbyImageData}*/}
            {/*    style={{ height: "25px" }}*/}
            {/*    alt=""*/}
            {/*  />*/}
            {/*  <SvgIcon*/}
            {/*    SvgComponent={LogoVisa}*/}
            {/*    hue="tusk"*/}
            {/*    shade="080"*/}
            {/*    size="md"*/}
            {/*  />*/}
            {/*  <SvgIcon*/}
            {/*    SvgComponent={LogoMastercard}*/}
            {/*    hue="tusk"*/}
            {/*    shade="080"*/}
            {/*    size="md"*/}
            {/*  />*/}
            {/*</SecureLogos>*/}
          </Grid>
        </CopyrightStrip>
      </LazyLoadWrapper>
    </FooterWrapper>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const FooterWrapper = styled.footer`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding-bottom: 55px;
  }
`
const SkinnyStrip = styled.div`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.midnight["100"]};
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .grid {
      grid-template-columns: 1fr;
      justify-items: center;
      grid-gap: 0;
      grid-template-rows: auto auto;
    }
  }
`
const CovidStrip = styled.div`
  color: #6f849e;
  font-size: 12px;
  background-color: ${({ theme }) => theme.colors.midnight["020"]};
  text-align: center;
  padding: 5px;
  a {
    color: #6f849e;
  }
`
const CopyrightStrip = styled.div`
  display: flex
  align-items: center;
  width: 100%;
  min-height: 56px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.midnight["100"]};
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .grid {
      grid-template-columns: 1fr;
      justify-items: center;
    }
  }
`
const CopyrightInfo = styled.div`
  color: #4b596b;
  font-size: 12px;
  a {
    color: #4b596b;
    transition: all 200ms ease-in-out;
    padding: 0 5px;
    &:hover {
      color: #fff;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding: 20px 0 10px 0;
    text-align: center;
  }
`

const SecureLogos = styled.div`
  display: grid;
  grid-template-columns: ${({ isHomePage }) =>
    isHomePage ? "56px auto auto 56px 56px 1fr" : "56px auto auto 56px 56px"};
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: ${({ isHomePage }) =>
      isHomePage ? "56px auto 56px 56px 1fr" : "56px auto 56px 56px"};
  }
  align-items: center;
  .icon:first-child {
    background-color: #040d19;
  }
  .icon {
    background-color: #050d1a;
    border-right: 1px solid #081729;
    padding: 12px;
  }
  .txt-label {
    color: ${({ theme }) => theme.colors.tusk["080"]};
    padding: 16px 12px;
  }
`
const FooterInner = styled.div`
  background-color: ${({ theme }) => theme.colors.midnight["060"]};
  padding: 30px 0;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .grid {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
`

// ==================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ==================

const QUERY = graphql`
  {
    payflexImage: file(relativePath: { eq: "payflex-slim.png" }) {
      childImageSharp {
        gatsbyImageData(height: 25, layout: FIXED)
      }
    }
    swiftcomImage: file(relativePath: { eq: "swift-logo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 20, layout: CONSTRAINED)
      }
    }
    menucopyright: allDataJson {
      nodes {
        footerDetails {
          label
          url
        }
      }
    }
    menularge: allDataJson {
      nodes {
        footerStructure {
          childItems {
            label
            url
          }
          label
          url
        }
      }
    }
  }
`

export default Footer
