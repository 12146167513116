import React from "react"
import EllipsisLoader from "../zzz/organisms/ellipsisLoader"
import { LazyLoadComponent } from "react-lazy-load-image-component"

// use this component to lazy load another component
//
// to lazy load a specific component, just add it as the lazyComponent prop:
// <LazyLoadWrapper lazyComponent={<MyComponent myData={data} />} />
//
// to lazy load all of the children just wrap the children in the LazyLoadWrapper
// <LazyLoadWrapper>
//  <MyComponent1 myData={data1} />
//  <MyComponent2 otherData={data2} />
//  <MyComponent3 someVar={data3} />
// </LazyLoadWrapper>
//

const LazyLoadWrapper = ({
  children,
  lazyComponent = false,
  placeholderHeight = "500px",
  placeholderComponent = <EllipsisLoader height={placeholderHeight} />,
}) => {
  return (
    <LazyLoadComponent
      style={{ display: "block" }}
      useIntersectionObserver={true}
      threshold={200}
      placeholder={placeholderComponent ? placeholderComponent : null}
    >
      {lazyComponent ? lazyComponent : children}
    </LazyLoadComponent>
  )
}

export default LazyLoadWrapper
