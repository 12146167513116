import React from "react"
import styled from "styled-components"
import colors from "../../colors"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"

const StyledBtnPillWrapper = styled.div`
  display: inline-block;
`
const StyledBtnPill = styled(Link)`
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 25px;
  background: ${(props) => (props.mode === "solid" ? props.color : "none")};
  border: 2px solid
    ${(props) => (props.mode === "outline" ? props.color : "none")};
  display: inline-block;
  padding: ${(props) =>
    props.size === "sm"
      ? "2px 10px"
      : props.size === "lg"
      ? "8px 20px"
      : "5px 15px"};
  font-size: ${(props) =>
    props.size === "sm"
      ? "0.875rem"
      : props.size === "lg"
      ? "1.125rem"
      : "1rem"};
  text-transform: uppercase;
  text-align: center;
  color: ${(props) =>
    props.mode === "outline" ? props.color : props.fontcolor};
  transition: all 200ms ease-in-out;
  &:hover {
    background: ${(props) => props.hovercolor};
    transform: translateY(-1px);
  }
`
const BtnPill = ({
  hue = "midnight",
  shade = "060",
  size = "md",
  to = "/",
  children = "Label",
  mode = "solid",
}) => {
  // Get Color
  const color = colors[hue][shade]
  // Calculate Hover Color
  let hoverShade = "100"
  if (parseInt(shade) !== 100) {
    if (String(parseInt(shade) + 20).length < 3) {
      hoverShade = "0" + String(parseInt(shade) + 20)
    } else {
      hoverShade = String(parseInt(shade) + 20)
    }
  }
  const hovercolor = colors[hue][hoverShade]
  // Determine Font Colour
  let fontcolor = "rgba(255,255,255,1)"
  const lightHues = ["tusk", "bg", "gold", "warning"]
  if (lightHues.includes(hue)) {
    fontcolor = "rgba(0,0,0,0.75)"
  }

  return (
    <StyledBtnPillWrapper className="button-wrapper">
      <StyledBtnPill
        color={color}
        hovercolor={hovercolor}
        fontcolor={fontcolor}
        size={size}
        to={to}
        mode={mode}
      >
        {children}
      </StyledBtnPill>
    </StyledBtnPillWrapper>
  )
}

const StyledBtnSquare = styled.button`
  margin-left: 10px;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 3px;
  background: ${(props) => (props.mode === "solid" ? props.color : "none")};
  border: none;
  display: inline-block;
  cursor: pointer;
  line-height: 1.1;
  padding: ${({ size }) =>
    size === "sm"
      ? "2px 10px"
      : size === "lg"
      ? "8px 20px"
      : (size = "xl" ? "15px 22px" : "8px 15px")};
  font-size: ${(props) =>
    props.size === "sm"
      ? "0.875rem"
      : props.size === "lg"
      ? "1.125rem"
      : (props.size = "xl" ? "1.2rem" : "1rem")};
  text-transform: uppercase;
  text-align: center;
  color: ${(props) =>
    props.mode === "outline" ? props.color : props.fontcolor};
  transition: all 200ms ease-in-out;
  &:hover {
    background: ${(props) => props.hovercolor};
    transform: translateY(-1px);
  }
`

const StyledLinkSquare = styled(Link)`
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 3px;
  background: ${(props) => (props.mode === "solid" ? props.color : "none")};
  border: 2px solid
    ${(props) => (props.mode === "outline" ? props.color : "none")};
  display: inline-block;
  line-height: 1.1;
  padding: ${({ size }) =>
    size === "sm"
      ? "2px 10px"
      : size === "lg"
      ? "8px 20px"
      : (size = "xl" ? "15px 22px" : "8px 15px")};
  font-size: ${(props) =>
    props.size === "sm"
      ? "0.875rem"
      : props.size === "lg"
      ? "1.125rem"
      : (props.size = "xl" ? "1.2rem" : "1rem")};
  text-transform: uppercase;
  text-align: center;
  color: ${(props) =>
    props.mode === "outline" ? props.color : props.fontcolor};
  transition: all 200ms ease-in-out;
  &:hover {
    background: ${(props) => props.hovercolor};
    transform: translateY(-1px);
  }
`
const StyledAnchorSquare = styled.a`
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 3px;
  background: ${(props) => (props.mode === "solid" ? props.color : "none")};
  border: 2px solid
    ${(props) => (props.mode === "outline" ? props.color : "none")};
  display: inline-block;
  line-height: 1.1;
  padding: ${({ size }) =>
    size === "sm"
      ? "2px 10px"
      : size === "lg"
      ? "8px 20px"
      : (size = "xl" ? "15px 22px" : "8px 15px")};
  font-size: ${(props) =>
    props.size === "sm"
      ? "0.875rem"
      : props.size === "lg"
      ? "1.125rem"
      : (props.size = "xl" ? "1.2rem" : "1rem")};
  text-transform: uppercase;
  text-align: center;
  color: ${(props) =>
    props.mode === "outline" ? props.color : props.fontcolor};
  transition: all 200ms ease-in-out;
  &:hover {
    background: ${(props) => props.hovercolor};
    transform: translateY(-1px);
  }
`
const BtnSquare = ({
  hue = "midnight",
  shade = "060",
  size = "md",
  children = "Label",
  mode = "solid",
  href,
  to,
  type,
  asButton,
  onClick,
  target,
}) => {
  // Get Color
  const color = colors[hue][shade]
  // Calculate Hover Color
  let hoverShade = "100"
  if (parseInt(shade) !== 100) {
    if (String(parseInt(shade) + 20).length < 3) {
      hoverShade = "0" + String(parseInt(shade) + 20)
    } else {
      hoverShade = String(parseInt(shade) + 20)
    }
  }
  const hovercolor = colors[hue][hoverShade]
  // Determine Font Colour
  let fontcolor = "rgba(255,255,255,1)"
  const lightHues = ["tusk", "bg", "gold", "warning"]
  if (lightHues.includes(hue)) {
    fontcolor = "rgba(0,0,0,0.75)"
  }

  const props = {
    color: color,
    hovercolor: hovercolor,
    fontcolor: fontcolor,
    size: size,
    to: to,
    mode: mode,
    type: type,
    onClick,
  }

  if (asButton) return <StyledBtnSquare {...props}>{children}</StyledBtnSquare>
  if (href)
    return (
      <StyledAnchorSquare {...props} href={href} target={target}>
        {children}
      </StyledAnchorSquare>
    )
  return <StyledLinkSquare {...props}>{children}</StyledLinkSquare>
}

const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: ${(props) => (props.align ? props.align : "center")};
`

export { BtnPill, BtnSquare, ButtonRow }
