import React, { memo } from "react"
import styled from "styled-components"
import Grid from "../../layout/grid"

import Searchbar from "../../../components/Search/Searchbar"
import { isInStoreFrontend } from "../../../services/auth"

import LogoSection from "./LogoSection"
import IconSection from "./IconSection"

const headerMain = () => {
  const isInStoreFrontendState = isInStoreFrontend()

  return (
    <HeaderMainWrapper>
      <Grid spacing={`1fr ${isInStoreFrontendState ? "180px " : ""}2fr 1fr`}>
        <LogoSection isInStoreFrontendParent={isInStoreFrontendState} />
        <Searchbar />
        <IconSection />
      </Grid>
    </HeaderMainWrapper>
  )
}

const HeaderMainWrapper = styled.div`
  background: ${(props) => props.theme.colors.midnight["080"]};
  color: ${({ theme }) => theme.colors.white["000"]};
  padding: 10px 0;
`

export default memo(headerMain)
