import React, { memo } from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Tooltip = memo(
  ({ children, text, background, color, minWidth = "100px" }) => {
    const [visible, setVisible] = React.useState(false)
    return (
      <Container
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className="tooltip"
      >
        <Text
          tooltipVisible={visible}
          background={background}
          color={color}
          dangerouslySetInnerHTML={{ __html: text }}
          minWidth={minWidth}
        />
        {children}
      </Container>
    )
  }
)

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  position: relative;
  @media (max-width: 786px) {
    padding: 0 20px;
  }
`

const Text = styled.div`
  opacity: ${({ tooltipVisible }) => (tooltipVisible ? "1" : "0")};
  transform: ${({ tooltipVisible }) =>
    tooltipVisible
      ? "translateX(-50%) scale(1) "
      : "translateX(-100%) scale(0) "};
  transform-origin: bottom right;
  transition: all 0.4s ease-in-out;
  position: absolute;
  background: ${({ background }) => background || "black"};
  color: ${({ color }) => color || "white"};
  bottom: 135%;
  min-width: ${({ minWidth }) => minWidth};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px;
  left: 50%;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 1px #00000038;
  white-space: pre;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid ${({ background }) => background || "black"};
    position: absolute;
    top: 100%;
  }
`

export default Tooltip
