import React from "react"

const IconDelivery = ({ fill }) => (
  <svg width="18" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.28 9.62c.12 0 .22.1.22.22v.44c0 .12-.1.22-.22.22h-1.57a2.19 2.19 0 11-4.29 0H6.08a2.19 2.19 0 11-4.29 0h-.44C.61 10.5 0 9.93 0 9.23V1.27C0 .57.6 0 1.35 0h8.67c.75 0 1.35.57 1.35 1.27v1.35h1.21c.46 0 .91.2 1.24.52l2.3 2.3c.32.32.5.77.5 1.23v2.95h.66zm-4.7-6.12h-1.2v2.63h4.16l-.05-.08-2.3-2.3a.87.87 0 00-.6-.25zm-8.64 8.75a1.31 1.31 0 100-2.63 1.31 1.31 0 000 2.63zm1.74-2.63h4.82V1.27c0-.22-.21-.4-.48-.4H1.35c-.26 0-.48.18-.48.4v7.96c0 .22.22.4.48.4h.85c.4-.53 1.03-.88 1.74-.88.7 0 1.34.35 1.74.87zm7.88 2.63a1.31 1.31 0 100-2.63 1.31 1.31 0 000 2.63zm0-3.5c.72 0 1.34.35 1.74.87h.45V7h-4.38v2.62h.45c.4-.52 1.03-.87 1.74-.87z"
      fill={fill}
    />
  </svg>
)

export default IconDelivery
