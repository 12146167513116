import React from "react"

const Calender = ({ fill }) => (
  <svg width="14" height="15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.81 2.5c.73 0 1.31.59 1.31 1.31v9.63c0 .72-.58 1.31-1.3 1.31H2.18c-.73 0-1.31-.59-1.31-1.31V3.8c0-.72.58-1.31 1.3-1.31H3.5V1.08c0-.18.15-.33.33-.33h.22c.18 0 .33.15.33.33V2.5h5.24V1.08c0-.18.15-.33.33-.33h.22c.18 0 .33.15.33.33V2.5h1.31zm-9.62.88c-.24 0-.44.2-.44.43v1.32h10.5V3.8c0-.24-.2-.43-.44-.43H2.2zm9.62 10.5c.24 0 .44-.2.44-.44V6H1.75v7.44c0 .24.2.43.44.43h9.62zM4.92 9.5h-1.1a.33.33 0 01-.32-.33v-1.1c0-.17.15-.32.33-.32h1.1c.17 0 .32.15.32.33v1.1c0 .17-.15.32-.33.32zm2.63 0h-1.1a.33.33 0 01-.33-.33v-1.1c0-.17.15-.32.33-.32h1.1c.18 0 .32.15.32.33v1.1c0 .17-.14.32-.32.32zm2.62 0h-1.1a.33.33 0 01-.32-.33v-1.1c0-.17.15-.32.33-.32h1.1c.17 0 .32.15.32.33v1.1c0 .17-.15.32-.33.32zm-2.62 2.63h-1.1a.33.33 0 01-.33-.33v-1.1c0-.18.15-.32.33-.32h1.1c.18 0 .32.14.32.32v1.1c0 .18-.14.33-.32.33zm-2.63 0h-1.1a.33.33 0 01-.32-.33v-1.1c0-.18.15-.32.33-.32h1.1c.17 0 .32.14.32.32v1.1c0 .18-.15.33-.33.33zm5.25 0h-1.1a.33.33 0 01-.32-.33v-1.1c0-.18.15-.32.33-.32h1.1c.17 0 .32.14.32.32v1.1c0 .18-.15.33-.33.33z"
      fill={fill}
    />
  </svg>
)

export default Calender
