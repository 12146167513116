/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import { getUser } from "../auth"

export const sendRetentionScience = ({
  viewProductId,
  cartContents,
  orderData,
  userInfo,
  viewCart = false,
}) => {
  if (isBrowser() && window) {
    // Create/clear global variable
    window._rsq = []
    window._rsq.push(["_setSiteId", process.env.GATSBY_RET_SCI_SITE_ID]) // replace site_id with your static Site ID
    window._rsq.push(["_enableOnsite"])

    // Add user ID and user email if user is logged in
    const userInfoSet = userInfo !== undefined ? userInfo : getUser()
    if (
      userInfoSet &&
      userInfoSet.tokenPayload &&
      userInfoSet.tokenPayload.data &&
      userInfoSet.tokenPayload.data.user
    ) {
      window._rsq.push(["_setUserId", userInfoSet.tokenPayload.data.user.id])
      window._rsq.push(["_setUserEmail", userInfoSet.user_email])
    }

    /*** EVENT SPECIFIC CODE ***/
    // View product page event
    if (viewProductId && window.location.pathname.includes("/product")) {
      window._rsq.push(["_addItem", { id: viewProductId }])
    }

    // Add to cart page event (not an order being placed though)
    else if (
      cartContents &&
      cartContents.length > 0 &&
      (window.location.pathname.includes("/checkout") || viewCart)
    ) {
      cartContents.forEach((product) => {
        if (!product.bundled_by) {
          console.log("Product in cart: ", product)
          window._rsq.push([
            "_addItem",
            {
              id: product.variation_id || product.product_id,
              name: `${product.product_name}${
                product.size ? " - " + product.size : ""
              }`,
              price: product.line_total,
            },
          ])
        }
      })
      window._rsq.push(["_setAction", "shopping_cart"])
    }
    // Order successfully placed page event
    else if (
      orderData &&
      orderData.line_items.length > 0 &&
      window.location.pathname.includes("/checkout")
    ) {
      window._rsq.push([
        "_addOrder",
        { id: orderData.id, total: orderData.total },
      ])

      orderData.line_items.forEach((product) => {
        if (!product.bundled_by) {
          window._rsq.push([
            "_addItem",
            {
              id: product.variation_id || product.product_id,
              name: `${product.title}${
                product.size ? " - " + product.size : ""
              }`,
              price: product.total,
            },
          ])
        }
      })
      window._rsq.push(["_setAction", "checkout_success"])
    }
    /*** END EVENT SPECIFIC CODE ***/

    window._rsq.push(["_track"])
    loadScriptAndSend()
  }
}

const loadScriptAndSend = () => {
  // Check to avoid server side rendering with window
  if (isBrowser() && window && window._rsq) {
    // Check if master, don't run tracking while testing
    if (process.env.GATSBY_ACTIVE_ENV != "production") {
      console.log("ReSci Tracking data (not sent): ", window._rsq)
      return
    } else {
      let head =
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      let oldScript = document.getElementById("retention_science_script")
      var newScript = document.createElement("script")
      newScript.type = "text/javascript"
      newScript.async = true
      newScript.src =
        ("https:" == document.location.protocol ? "https://" : "http://") +
        "d1stxfv94hrhia.cloudfront.net/waves/v3/w.js"

      // If we already have a "retention_science_script" script loaded, delete it and load a new one
      if (oldScript) {
        head.removeChild(oldScript)
      }
      newScript.id = "retention_science_script"
      head.appendChild(newScript)
    }
  }
}

const isBrowser = () => typeof window !== "undefined"
