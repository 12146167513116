import React, { memo, useState, useEffect } from "react"
import styled from "styled-components"
import MenuLoader from "../../zzz/organisms/menuLoader"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ChevronUp from "../../zzz/atoms/icons/files/chevron-up"
import ChevronDown from "../../zzz/atoms/icons/files/chevron-down"

const DonateYourBed = ({ small = false, centered = false }) => {
  const [loading, setLoading] = useState(false)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [hasCheckedCheckboxValue, setHasCheckedCheckboxValue] = useState(false)

  const [donateFieldState, setDonateFieldState] = useState(false)
  const toggleDonateFieldState = () => setDonateFieldState(!donateFieldState)

  const PreviousCheckboxValue =
    localStorage.getItem("donateMyBed") === "true" ? true : false

  if (
    PreviousCheckboxValue &&
    typeof PreviousCheckboxValue == "boolean" &&
    checkboxValue !== PreviousCheckboxValue &&
    hasCheckedCheckboxValue === false
  ) {
    setHasCheckedCheckboxValue(true)
    setCheckboxValue(PreviousCheckboxValue)
  }

  function toggleCheckboxValue(value) {
    localStorage.setItem("donateMyBed", !value)
    return !value
  }

  return (
    <Wrap className={`${small && "small"} ${centered && "centered"}`}>
      <Text
        className={"donate_field_title strong"}
        onClick={toggleDonateFieldState}
      >
        Donate your old bed to the Salvation Army?{" "}
        {donateFieldState ? (
          <SvgIcon size="sm" SvgComponent={ChevronUp} />
        ) : (
          <SvgIcon size="sm" SvgComponent={ChevronDown} />
        )}
      </Text>
      <Container
        className={`donate_field_container ${
          donateFieldState ? `show` : `hide`
        }`}
      >
        <Text>
          <p>We will make sure your old bed finds someone in need.</p>
        </Text>
        <InputWrap>
          {loading ? (
            <MenuLoader color="#1A293C" center />
          ) : (
            <InputWrapInner className={centered && "centered"}>
              <Input
                type="checkbox"
                id="donateBedCheckbox"
                checked={checkboxValue}
                onChange={(e) => {
                  setCheckboxValue(toggleCheckboxValue)
                }}
              />
              <label for="donateBedCheckbox">
                Yes, Please collect my old bed/mattress.
              </label>
            </InputWrapInner>
          )}
        </InputWrap>
      </Container>
    </Wrap>
  )
}

export default DonateYourBed

const Wrap = styled.div`
  div.error,
  div.message {
    text-align: center;
    margin: 10px 0;
  }

  div.error {
    color: #fd3237;
  }

  div.message {
    color: #48b6dd;
  }

  .donate_field_title {
    text-align: center;
    font-weight: bold;
    padding-left: 0;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .strong {
    font-weight: bold;
  }

  width: 100%;
  font-size: 16px;
  &.small {
    * {
      font-size: 14px;
    }
    input {
      padding: 5px;
    }
    span.icon {
      padding: 0 5px;
    }
    .donate_field_title {
      font-size: 14px;
      text-align: left;
    }
  }
  &.centered {
    text-align: center;
    .donate_field_title {
      text-align: center;
    }
  }
`

const Text = styled.div`
  padding-left: 10px;
  p {
    margin-bottom: 5px;
  }
`

const Container = styled.div`
  margin: 10px 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  &.donate_field_container {
    &.hide {
      display: none;
    }
  }
`

const InputWrap = styled.form`
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease-in-out;
`

const InputWrapInner = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  padding-left: 7px;
  &.centered {
    justify-content: center !important;
  }
`

const Input = styled.input`
  border: none;
  padding: 10px;
  &:active,
  &:focus {
    outline: none;
  }
`
