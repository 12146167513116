import React from "react"

const SocialInstagram = ({ fill }) => (
  <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 3.406c.646 0 1.245.162 1.797.485.552.323.99.76 1.312 1.312.323.552.485 1.151.485 1.797 0 .646-.162 1.245-.485 1.797-.323.552-.76.99-1.312 1.312A3.497 3.497 0 017 10.594a3.497 3.497 0 01-1.797-.485 3.604 3.604 0 01-1.312-1.312A3.497 3.497 0 013.406 7c0-.646.162-1.245.485-1.797.323-.552.76-.99 1.312-1.312A3.497 3.497 0 017 3.406zm0 5.938c.646 0 1.198-.23 1.656-.688.459-.458.688-1.01.688-1.656 0-.646-.23-1.198-.688-1.656A2.258 2.258 0 007 4.656c-.646 0-1.198.23-1.656.688A2.258 2.258 0 004.656 7c0 .646.23 1.198.688 1.656.458.459 1.01.688 1.656.688zm4.594-6.094a.974.974 0 01-.266.594.761.761 0 01-.578.25.811.811 0 01-.594-.25.811.811 0 01-.25-.594c0-.23.084-.427.25-.594a.811.811 0 01.594-.25c.23 0 .427.084.594.25.166.167.25.365.25.594zm2.375.844C13.989 4.677 14 5.646 14 7s-.016 2.328-.047 2.922c-.031.594-.12 1.11-.266 1.547a3.642 3.642 0 01-2.219 2.219c-.437.145-.952.234-1.546.265C9.328 13.984 8.354 14 7 14s-2.328-.016-2.922-.047c-.594-.031-1.11-.13-1.547-.297a3.331 3.331 0 01-1.36-.828 3.642 3.642 0 01-.859-1.36c-.145-.437-.234-.952-.265-1.546C.016 9.328 0 8.354 0 7s.016-2.328.047-2.922c.031-.594.12-1.11.265-1.547.188-.52.474-.974.86-1.36a3.642 3.642 0 011.36-.859c.437-.145.952-.234 1.546-.265C4.672.016 5.646 0 7 0s2.328.016 2.922.047c.594.031 1.11.12 1.547.265.52.188.974.474 1.36.86.385.385.671.838.858 1.36.146.437.24.958.282 1.562zm-1.5 7.031c.125-.354.208-.917.25-1.688.02-.458.031-1.104.031-1.937v-1c0-.854-.01-1.5-.031-1.938-.042-.791-.125-1.354-.25-1.687a2.255 2.255 0 00-1.344-1.344c-.333-.125-.896-.208-1.688-.25A45.592 45.592 0 007.5 1.25h-1c-.833 0-1.48.01-1.938.031-.77.042-1.333.125-1.687.25a2.255 2.255 0 00-1.344 1.344c-.125.333-.208.896-.25 1.687-.02.459-.031 1.105-.031 1.938v1c0 .833.01 1.48.031 1.937.042.771.125 1.334.25 1.688.271.646.719 1.094 1.344 1.344.354.125.917.208 1.687.25.459.02 1.105.031 1.938.031h1c.854 0 1.5-.01 1.937-.031.792-.042 1.355-.125 1.688-.25.646-.271 1.094-.719 1.344-1.344z"
      fill={fill}
    />
  </svg>
)

export default SocialInstagram
