import React, { memo } from "react"
import styled from "styled-components"
import SvgIcon from "../../atoms/icons/svg-icon"
import Home from "../../atoms/icons/files/home.jsx"
import ChevronRight from "../../atoms/icons/files/chevron-right.jsx"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"
import { buildCrumbs } from "../../../lib/util"

const BreadcrumbsWrapper = styled.div`
  padding: 10px 0;
  div:first-child {
    padding-right: 5px;
  }
  a {
    color: ${({ theme, color }) => (color ? color : theme.colors.tusk["060"])};
    padding: 20px 10px;
    transition: all 200ms ease-in-out;
    text-transform: capitalize;
    &:hover {
      color: ${({ theme }) => theme.colors.ocean["040"]};
    }
  }
  span {
    text-transform: capitalize;
    color: ${({ color }) => color && color};
  }
`

const Breadcrumbs = memo(({ pathname, product = {}, color }) => {
  const crumbs = buildCrumbs(product, pathname)

  return (
    <BreadcrumbsWrapper color={color}>
      <SvgIcon SvgComponent={Home} hue="tusk" shade="080" size="sm" />
      <Link to="/">Home</Link>
      {crumbs != undefined && crumbs.length > 0 && (
        <SvgIcon SvgComponent={ChevronRight} hue="tusk" shade="100" size="sm" />
      )}
      {crumbs.map(({ name, link }, i) => {
        return (
          <span key={i}>
            {i < crumbs.length - 1 && <Link to={link}>{name}</Link>}
            {i < crumbs.length - 1 && (
              <SvgIcon
                SvgComponent={ChevronRight}
                hue="tusk"
                shade="100"
                size="sm"
              />
            )}
            {i == crumbs.length - 1 && name}
          </span>
        )
      })}
    </BreadcrumbsWrapper>
  )
})

export default Breadcrumbs
