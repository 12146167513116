import React from "react"

const IconCheck = ({ fill }) => (
  <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.434 11.697l-5.2-5.2a.8.8 0 010-1.131l1.132-1.132a.8.8 0 011.131 0L6 7.737 13.503.234a.8.8 0 011.131 0l1.132 1.132a.8.8 0 010 1.131l-9.2 9.2a.8.8 0 01-1.132 0z"
      fill={fill}
    />
  </svg>
)

export default IconCheck
